import styled from "styled-components/native";

import { Text3, Text4 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

export const Container = styled.View`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Content = styled.View`
  background-color: ${colors.white};
  border-radius: ${spacers.ss4};
  padding-bottom: ${spacers.ss5};
  overflow: hidden;
  width: 320px;
`;

export const ImageContent = styled.View``;

export const Image = styled.Image<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export const WebImage = styled.Image`
  height: undefined;
  width: 100%;
  aspect-ratio: 1;
`;

export const TextContent = styled.View`
  padding: ${spacers.ss6} ${spacers.ss7};
`;

export const ButtonContent = styled.View`
  padding-horizontal: ${spacers.ss6};
  padding-bottom: ${spacers.ss2};
`;

export const CloseButton = styled.TouchableOpacity`
  background-color: ${colors.white};
  padding: ${spacers.ss4};
  border-radius: 20px;
  pasition: absolute;
  bottom: -10px;
  right: -160px;
  z-index: 9;
`;

export const Title = styled(Text4)`
  margin-bottom: ${spacers.ss3};
  font-family: SFProDisplayMedium;
  letter-spacing: 0.5px;
`;

export const Description = styled(Text3)`
  color: ${colors.gray500};
  letter-spacing: 0.5px;
`;
