export enum Screens {
  AmountSelection = "amount",
  PaymentDetails = "details",
  Success = "success",
  Intro = "account",
  History = "history",
  Manage = "manage",
  TaxReceipts = "tax-receipts",
  PaymentMethod = "payment-method",
  PaymentMethodAdd = "payment-method-add",
}

export enum Frequency {
  Monthly = "monthly",
  OneTime = "onetime",
}
