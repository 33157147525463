import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { Announcement } from "./types";

const getState = (state: RootState) => state.announcements;

export const getAnnouncements: (state: RootState) => Announcement[] =
  createSelector(getState, (state) => state?.data || []);

export const getAnnouncementById: (
  state: RootState,
  id: string
) => Announcement | undefined = createSelector(
  [getAnnouncements, (_, props) => props],
  (data, id) => data.find((item) => item.id === id)
);
