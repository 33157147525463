export const messages = {
  deleteConfirmation: {
    id: "content.delete-confirmation",
    defaultMessage: "Delete Item",
  },
  deleteConfirmationDescription: {
    id: "content.delete-description",
    defaultMessage: "Are you sure you want to proceed?",
  },
};
