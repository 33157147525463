import React from "react";
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { IconWithBadge } from "~/components/icon/icon-with-badge";
import { GroupAvatar } from "~/components/group-avatar";
import { AvatarSize } from "~/components/group-avatar/types";
import { IconSizes } from "~/components/icon";
import { BackButton } from "~/components/back-button";
import { TutorialStep } from "~/components/tutorial-step";
import { getUnreadGroupMessagesCount } from "~/state/groups/selectors";
import { useAppSelector } from "~/state/hooks";
import { useTutorial } from "~/state/tutorial";
import { getTutorialStep } from "~/state/tutorial/selectors";

import { colors } from "~/styles/theme";

import { Container, SideBox, CenterBox, GroupText, IconBox } from "./styles";
import { messages } from "./intl";

interface Props {
  groupId: string;
  name: string;
  uri?: string;
  count?: number;
  hideChatIcon?: boolean;
  hideBackButton?: boolean;
}

export const GroupHeaderBar = React.memo<Props>(
  ({ groupId, name, uri, count = 0, hideBackButton, hideChatIcon }) => {
    const route = useRoute();
    const isFocused = useIsFocused();
    const { showTutorial } = useTutorial(route.name, isFocused);

    const unreadMessagesCount = useAppSelector((state) =>
      getUnreadGroupMessagesCount(state, groupId)
    );

    const navigation = useNavigation<{
      navigate: (route: string, options: { groupId: string }) => void;
      canGoBack: () => boolean;
    }>();

    const handleOpenModal = React.useCallback(
      () => navigation.navigate(routes.groupModal, { groupId }),
      [navigation, groupId]
    );

    const handleOpenChat = React.useCallback(
      () => navigation.navigate(routes.groupChat, { groupId }),
      [navigation, groupId]
    );

    const message = hideChatIcon
      ? name
      : { ...messages.text, values: { name, count } };

    const tutorialStep = useAppSelector((state) =>
      getTutorialStep(state, "groups-modal")
    );

    React.useEffect(() => {
      showTutorial(tutorialStep);
    }, [showTutorial, tutorialStep]);

    return (
      <Container>
        <SideBox>
          {!hideBackButton && navigation.canGoBack() ? <BackButton /> : null}
        </SideBox>

        <TutorialStep id="GROUPS.CONTENT.PROFILE">
          <CenterBox onPress={handleOpenModal}>
            <GroupAvatar text={name} uri={uri} size={AvatarSize.Small} />
            <GroupText>{message}</GroupText>
          </CenterBox>
        </TutorialStep>

        <SideBox>
          {!hideChatIcon ? (
            <IconBox>
              <TutorialStep id="GROUPS.CONTENT.MESSAGES">
                <IconWithBadge
                  badgeCount={unreadMessagesCount}
                  onPress={handleOpenChat}
                  name="chat"
                  size={IconSizes.Medium}
                  color={colors.black}
                />
              </TutorialStep>
            </IconBox>
          ) : null}
        </SideBox>
      </Container>
    );
  }
);
