export const genericMessages = {
  cancel: {
    id: "generic.cancel",
    defaultMessage: "Cancel",
  },
  remove: {
    id: "generic.remove",
    defaultMessage: "Remove",
  },
  delete: {
    id: "generic.delete",
    defaultMessage: "Delete",
  },
  ok: {
    id: "generic.ok",
    defaultMessage: "Ok",
  },
  back: {
    id: "generic.back",
    defaultMessage: "Back",
  },
  save: {
    id: "generic.save",
    defaultMessage: "Save",
  },
  close: {
    id: "generic.close",
    defaultMessage: "Close",
  },
  yes: {
    id: "generic.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "generic.no",
    defaultMessage: "No",
  },
  yesSure: {
    id: "generic.yes-sure",
    defaultMessage: "Yes, I am sure",
  },
  error: {
    id: "generic.error",
    defaultMessage: "Something went wrong. Please try again later.",
  },
  saveSuccess: {
    id: "generic.save-success",
    defaultMessage: "The changes have been successfully saved!",
  },
  noteDeleted: {
    id: "generic.note-deleted",
    defaultMessage: "The note has been successfully deleted",
  },
  itemDeleted: {
    id: "generic.item-deleted",
    defaultMessage: "The item has been successfully deleted",
  },
  errorTooManyRequests: {
    id: "generic.error.too-many-requests",
    defaultMessage:
      "Too many requests in a short time. Please wait a moment before sending another request.",
  },
  featureUnavailableOffline: {
    id: "generic.feature-unavailable-offline",
    defaultMessage: "This feature is unavailable offline",
  },
};
