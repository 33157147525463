import { createAction } from "@reduxjs/toolkit";

import {
  GetInAppNotificationsPayload,
  AddInAppNotificationPayload,
  EditInAppNotificationPayload,
  DeleteInAppNotificationPayload,
} from "./types";

export const getInAppNotifications = createAction<GetInAppNotificationsPayload>(
  "getInAppNotifications"
);

export const addInAppNotification = createAction<AddInAppNotificationPayload>(
  "addInAppNotification"
);

export const editInAppNotification = createAction<EditInAppNotificationPayload>(
  "editInAppNotification"
);

export const removeInAppNotification =
  createAction<DeleteInAppNotificationPayload>("removeInAppNotification");
