import { createAction } from "@reduxjs/toolkit";

import {
  GetAnnouncementsPayload,
  AddAnnouncementPayload,
  EditAnnouncementPayload,
  DeleteAnnouncementPayload,
} from "./types";

export const getAnnouncements =
  createAction<GetAnnouncementsPayload>("getAnnouncements");

export const addAnnouncement =
  createAction<AddAnnouncementPayload>("addAnnouncement");

export const editAnnouncement =
  createAction<EditAnnouncementPayload>("editAnnouncement");

export const removeAnnouncement =
  createAction<DeleteAnnouncementPayload>("removeAnnouncement");
