import styled from "styled-components/native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { TextInput } from "~/components/text-input";

export const OptionBox = styled.View`
  flex-direction: row;
  margin: ${spacers.ss5} ${spacers.ss4} ${spacers.ss2};
  width: 100%;
`;

export const OptionInput = styled(TextInput)`
  margin-right: ${spacers.ss6};
`;

export const ButtonBox = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  margin: ${spacers.ss5} ${spacers.ss4};
`;

export const ButtonText = styled(Text2)<{ type?: string }>`
  font-family: SFProDisplayMedium;
  color: ${({ type }) =>
    type === "error" ? colors.red600 : colors.primaryBlue};
`;

export const ButtonIcon = styled(MaterialCommunityIcons).attrs({
  size: 14,
  color: colors.primaryBlue,
})``;
