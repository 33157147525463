import styled from "styled-components/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { colors } from "~/styles/theme";
import { IconSizes } from "~/components/icon";
import { Text3 } from "~/components/text";

export const DeleteAccountButtonContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  padding: 20px 0px 40px;
`;

export const DeleteAccountButton = styled.TouchableOpacity`
  flex-direction: row;
`;

export const DeleteAccountButtonIcon = styled(MaterialCommunityIcons).attrs({
  name: "delete-forever",
  color: colors.red500,
  size: IconSizes.SMedium,
})``;

export const DeleteAccountButtonText = styled(Text3)`
  color: ${colors.red500};
`;
