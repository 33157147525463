import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InAppNotificationsState,
  InAppNotification,
  EditInAppNotification,
} from "./types";

const initialState: InAppNotificationsState = {
  data: [],
};

export const inAppNotificationsDataSlice = createSlice({
  name: "in-app-notifications-data",
  initialState,
  reducers: {
    updateInAppNotifications: (
      state,
      action: PayloadAction<InAppNotification[]>
    ) => {
      state.data = action.payload;
    },
    addInAppNotification: (state, action: PayloadAction<InAppNotification>) => {
      state.data = [...state.data, action.payload];
    },
    deleteInAppNotification: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter(({ id }) => id !== action.payload);
    },
    editInAppNotification: (
      state,
      action: PayloadAction<EditInAppNotification>
    ) => {
      state.data = state.data.map((inAppNotification) => {
        if (inAppNotification.id !== action.payload.id) {
          return inAppNotification;
        }
        return {
          ...inAppNotification,
          ...action.payload,
        };
      });
    },
  },
});

export const {
  updateInAppNotifications,
  addInAppNotification,
  deleteInAppNotification,
  editInAppNotification,
} = inAppNotificationsDataSlice.actions;

export default inAppNotificationsDataSlice.reducer;
