import { SagaIterator } from "redux-saga";
import { call, takeLatest, put } from "redux-saga/effects";
import { remoteConfig } from "~/utils/remote-config";

import { configDefaults } from "~/constants/remote-config";
import { setIsUserReady } from "~/state/user/actions";

import { setRemoteConfig } from "./slice";

const getRemoteConfig = async () => {
  return await remoteConfig()
    .setDefaults(configDefaults)
    .then(() => remoteConfig().fetchAndActivate())
    .then((fetchedRemotely) => {
      if (fetchedRemotely) {
        console.log("Configs were retrieved from the backend and activated.");
      } else {
        console.log(
          "No configs were fetched from the backend, and the local configs were already activated"
        );
      }
    })
    .then(() => remoteConfig().getAll());
};

export function* handleRemoteConfig(): SagaIterator {
  try {
    const data = yield call(getRemoteConfig);

    if (!data) {
      yield put(setRemoteConfig(configDefaults));
      return;
    }

    const config = Object.keys(data).reduce(
      (acc, key) => {
        if (data[key].getSource() === "default") {
          return acc;
        }
        // @ts-ignore
        acc[key] = data[key].asBoolean();
        return acc;
      },
      { ...configDefaults }
    );

    yield put(setRemoteConfig(config));
  } catch (e) {
    console.log({ e });
  }
}

export function* remoteConfigSaga() {
  yield takeLatest(setIsUserReady.type, handleRemoteConfig);
}
