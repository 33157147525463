import * as React from "react";
import axios from "axios";
import Constants from "expo-constants";

import { PLACES_API_URL } from "~/constants";

import { logError } from "./logger";

const API_KEY = Constants?.expoConfig?.extra?.googlePlacesApiKey;

type GooglePlaceData = {
  description: string;
  id: string;
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
  };
};

export const useGooglePlaces = (
  query: string,
  type: string,
  minLength: number = 3
) => {
  const [places, setPlaces] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchPlaces = async () => {
      if (!query || query.length < minLength) {
        setPlaces([]);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(PLACES_API_URL, {
          params: {
            input: query,
            key: API_KEY,
            type,
          },
        });

        if (response.data.status === "OK") {
          const items = (response.data?.predictions as GooglePlaceData[])?.map(
            ({ structured_formatting }) => structured_formatting?.main_text
          );
          if (Array.isArray(items)) {
            setPlaces(items);
          }
        } else {
          setError(response.data?.status);
        }
      } catch (e: Error) {
        setError(e?.message);
        logError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchPlaces();
  }, [query, type]);

  return { places, loading, error };
};
