export const messages = {
  enabled: {
    id: "in-app.enabled",
    defaultMessage: "Enabled?",
  },
  type: {
    id: "in-app.type",
    defaultMessage: "Type",
  },
  typeModal: {
    id: "in-app.type-modal",
    defaultMessage: "Dialog",
  },
  typeBanner: {
    id: "in-app.type-banner",
    defaultMessage: "Banner",
  },
  description: {
    id: "in-app.description",
    defaultMessage: "Description ",
  },
  title: {
    id: "in-app.title",
    defaultMessage: "Title",
  },
  schedule: {
    id: "in-app.schedule",
    defaultMessage: "Send At",
  },
  expiration: {
    id: "in-app.expiration",
    defaultMessage: "Expire At",
  },
  buttonText: {
    id: "in-app.button-text",
    defaultMessage: "Button Text",
  },
  buttonLink: {
    id: "in-app.button-link",
    defaultMessage: "Call To Action Button",
  },
  buttonLinkScreen: {
    id: "in-app.button-link-screen",
    defaultMessage: "Open Screen",
  },
  buttonLinkUrl: {
    id: "in-app.button-link-url",
    defaultMessage: "URL",
  },
  buttonLinkDisclaimer: {
    id: "in-app.button-link-disclaimer",
    defaultMessage:
      "Screen or external URL where the user will be redirected after tapping on the button",
  },
  image: {
    id: "in-app.image",
    defaultMessage: "Image",
  },
  imageDisclaimer: {
    id: "in-app.image-disclaimer",
    defaultMessage: "Image",
  },
  screen: {
    id: "in-app.screen",
    defaultMessage: "Screen name",
  },
  screenDisclaimer: {
    id: "in-app.screen-disclaimer",
    defaultMessage: "Screen where the message should appear",
  },
  internalLink: {
    id: "in-app.internal-link",
    defaultMessage: "Internal Link",
  },
  externalLink: {
    id: "in-app.external-link",
    defaultMessage: "External Link",
  },
  options: {
    id: "in-app.options",
    defaultMessage: "Options",
  },
  optionsTooltip: {
    id: "in-app.options-tooltip",
    defaultMessage:
      "Used to pass extra data to the app, for example to pre-fill a donation amount when navigating to the Giving screen",
  },
  optionsDisclaimer: {
    id: "in-app.options-disclaimer",
    defaultMessage: "Additional data sent with the message",
  },
};
