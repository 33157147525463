import * as React from "react";
import axios from "axios";
import {
  doc,
  getDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";

import { database } from "<config>/firebase";
import { handleError } from "~/utils/logger";
import { getApiHeaders } from "~/state/payments/utils";
import { WEB_APP_URL } from "~/constants";

export const useCityData = (country?: string, firstLetter?: string) => {
  const [data, setData] = React.useState(new Map());
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!country || !firstLetter) {
      return;
    }
    const fetchData = async () => {
      try {
        const document = await getDoc(
          doc(
            database,
            "cities_data",
            country,
            "cities",
            firstLetter.toUpperCase()
          )
        );

        if (document?.exists()) {
          const cityData = (document.data()?.cities || []).reduce(
            (acc: Map<string, string>, item: any) => {
              if (item?.name) {
                acc.set(item.name, item.name);
              }

              return acc;
            },
            new Map()
          );

          setData(cityData);
        }
        setLoading(false);
      } catch (error) {
        handleError(`Error fetching location document: ${error}`);
        setLoading(false);
      }
    };

    fetchData();
  }, [country, firstLetter]);

  return { data, loading };
};

export const useZipCodeData = (churchZip: string) => {
  const [data, setData] = React.useState(new Map());
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!churchZip || churchZip.length < 3) {
      return;
    }

    const fetchData = async () => {
      try {
        const headers = await getApiHeaders();
        const response = await axios.get(
          `${WEB_APP_URL}/api/search/postcode?postCode=${churchZip}`,
          headers
        );

        if (Array.isArray(response?.data)) {
          const valuesSet = new Set();
          const zipcodeData = (response?.data).reduce(
            (acc: Map<string, string>, item: any) => {
              if (item?.id) {
                const value = `${item.postalCode}, ${item.city}, ${item.state}, ${item.country}`;
                if (valuesSet.has(value)) {
                  return acc;
                }
                acc.set(item.id, value);
                valuesSet.add(value);
              }

              return acc;
            },
            new Map()
          );

          setData(zipcodeData);
        }
        setLoading(false);
      } catch (error) {
        handleError(`Error fetching location document: ${error}`);
        setLoading(false);
      }
    };

    fetchData();
  }, [churchZip]);

  return { data, loading };
};

export const useChurchNameData = (
  churchName: string,
  churchCity: string = "",
  churchState: string = ""
) => {
  const [data, setData] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!churchCity) {
      return;
    }

    const fetchData = async () => {
      try {
        const q = query(
          collection(database, "church_data"),
          where("churchCity", "==", churchCity),
          where("churchState", "==", churchState)
        );

        const snapshot = await getDocs(q);

        if (snapshot) {
          const documents: string[] = [];

          snapshot.docs?.forEach((item) => {
            if (item?.data()?.churchName) {
              const name = item.data().churchName;
              documents.push(name);
            }
          });

          setData(documents);
        }
        setLoading(false);
      } catch (error) {
        handleError(`Error fetching location document: ${error}`);
        setLoading(false);
      }
    };

    fetchData();
  }, [churchCity, churchState]);

  return { data, loading };
};

export const getKeyByValue = (map: Map<string, string>, value: string) => {
  for (const [key, val] of map.entries()) {
    if (val === value) {
      return key;
    }
  }
};

export const getValuesFromMap = (
  data: Map<string, string>,
  value: string,
  ignoreFilter?: boolean
) => {
  const entries = Array.from(data.entries());
  const predicate = ([key, name]: [string, string]) => ({
    key,
    value: name,
  });
  const sort = (a, b) => b.value - a.value;
  if (ignoreFilter) {
    return entries.map(predicate).sort(sort);
  }
  return entries
    .filter(([_, name]) => name?.toLowerCase().startsWith(value.toLowerCase()))
    .map(predicate)
    .sort(sort);
};
