import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RemindersState, Reminder, EditReminder } from "./types";

const initialState: RemindersState = {
  data: [],
};

export const remindersSlice = createSlice({
  name: "reminders",
  initialState,
  reducers: {
    updateReminders: (state, action: PayloadAction<Reminder[]>) => {
      state.data = action.payload;
    },
    addReminder: (state, action: PayloadAction<Reminder>) => {
      state.data = [...state.data, action.payload];
    },
    deleteReminder: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter(({ id }) => id !== action.payload);
    },
    editReminder: (state, action: PayloadAction<EditReminder>) => {
      state.data = state.data.map((reminder) => {
        if (reminder.id !== action.payload.id) {
          return reminder;
        }
        return {
          ...reminder,
          ...action.payload,
        };
      });
    },
  },
});

export const { updateReminders, addReminder, deleteReminder, editReminder } =
  remindersSlice.actions;

export default remindersSlice.reducer;
