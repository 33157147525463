import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  FlamelinkState,
  FlamelinkMeta,
  FlamelinkImage,
  SetDownloadsPayload,
  FlamelinkFile,
  Session,
  PersonalDevotionsContent,
  LessonsContent,
  FeaturedContent,
} from "./types";

export const initialState: FlamelinkState = {
  data: {},
  images: [],
  downloads: {},
  downloadedSessions: [],
  files: [],
  sessions: {},
  personalDevotions: {},
  lessons: {},
  featured: [],
  featuredFetchTime: 0,
};

export const flamelinkSlice = createSlice({
  name: "flamelink",
  initialState,
  reducers: {
    setFlamelinkData: (state, action: PayloadAction<FlamelinkMeta>) => {
      state.data = action.payload;
    },
    setFlamelinkImages: (state, action: PayloadAction<FlamelinkImage[]>) => {
      state.images = action.payload;
    },
    setDownloads: (state, action: PayloadAction<SetDownloadsPayload>) => {
      state.downloads = {
        ...state.downloads,
        [action.payload.id]: action.payload,
      };
    },
    setDownloadsBulk: (
      state,
      action: PayloadAction<Map<string, SetDownloadsPayload>>
    ) => {
      const data = Object.fromEntries(action.payload);
      state.downloads = {
        ...state.downloads,
        ...data,
      };
    },
    removeDownload: (state, action: PayloadAction<string>) => {
      delete state.downloads[action.payload];
    },
    removeAllDownloads: (state) => {
      state.downloads = {};
    },
    setDownloadedSession: (state, action: PayloadAction<string>) => {
      state.downloadedSessions = [...state.downloadedSessions, action.payload];
    },
    setDownloadedSessions: (state, action: PayloadAction<string[]>) => {
      state.downloadedSessions = [
        ...state.downloadedSessions,
        ...action.payload,
      ];
    },
    removeDownloadedSession: (state, action: PayloadAction<string>) => {
      state.downloadedSessions = state.downloadedSessions.filter(
        (id) => id !== action.payload
      );
    },
    removeAllDownloadedSessions: (state) => {
      state.downloadedSessions = [];
    },
    setFile: (state, action: PayloadAction<FlamelinkFile>) => {
      state.files = [...state.files, action.payload];
    },
    setFiles: (state, action: PayloadAction<FlamelinkFile[]>) => {
      state.files = [...state.files, ...action.payload];
    },
    resetFlamelinkData: (state) => {
      state.files = [];
      state.data = {};
    },

    setSession: (state, action: PayloadAction<Session>) => {
      // @ts-ignore
      state.sessions[action.payload.id] = action.payload;
    },

    setPersonalDevotion: (
      state,
      action: PayloadAction<PersonalDevotionsContent>
    ) => {
      state.personalDevotions[action.payload.id] = action.payload;
    },

    setLesson: (state, action: PayloadAction<LessonsContent>) => {
      state.lessons[action.payload.id] = action.payload;
    },

    setFeaturedContent: (state, action: PayloadAction<FeaturedContent[]>) => {
      state.featured = action.payload;
      state.featuredFetchTime = new Date().getTime();
    },

    resetFeaturedFetchTime: (state) => {
      state.featuredFetchTime = 0;
    },
  },
});

export const {
  setFlamelinkData,
  setFlamelinkImages,
  setDownloads,
  setDownloadsBulk,
  removeDownload,
  removeAllDownloads,
  setFile,
  setFiles,
  setSession,
  setPersonalDevotion,
  setLesson,
  setDownloadedSession,
  setDownloadedSessions,
  removeDownloadedSession,
  removeAllDownloadedSessions,
  setFeaturedContent,
  resetFeaturedFetchTime,
  resetFlamelinkData,
} = flamelinkSlice.actions;

export default flamelinkSlice.reducer;
