import { Dimensions } from "react-native";
import styled, { css } from "styled-components/native";

import { spacers } from "~/styles/theme";
import { IconSizes } from "~/components/icon";
import { HEADER_BAR_HEIGHT } from "~/constants";
import { Text1Center, Text2Center } from "../text";

const badgeStyle = css<{ color: string }>`
  border-color: ${({ color }) => color};
  margin-right: ${spacers.ss6};
  border-width: 1px;
  border-radius: 4px;
  padding: 2px 3px;
`;

export const HeaderBarContainer = styled.View`
  flex-direction: row;
  height: ${HEADER_BAR_HEIGHT}px;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`;

export const HeaderBarBackButtonContainer = styled.View`
  width: 130px;
  justify-content: flex-start;
`;

export const HeaderIconsContainer = styled.View`
  width: 130px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 ${spacers.ss5};
`;

export const HeaderIconWrapper = styled.View<{ isFirst?: boolean }>`
  margin-right: ${({ isFirst }) => (isFirst ? spacers.ss8 : spacers.ss7)};
  height: ${IconSizes.Medium}px;
`;

export const HeaderAvatar = styled.Pressable`
  height: ${HEADER_BAR_HEIGHT}px;
  width: ${HEADER_BAR_HEIGHT}px;
`;

export const MiniLogo = styled.Image`
  width: 30px;
  height: 30px;
  position: absolute;
  left: ${Dimensions.get("window").width / 2 - 15}px;
`;

export const HeaderBarTitle = styled(Text2Center)`
  font-family: SFProDisplayMedium;
`;

export const Badge = styled.View`
  ${badgeStyle};
  margin-right: ${spacers.ss5};
`;

export const BadgeButton = styled.TouchableOpacity`
  ${badgeStyle}
`;

export const BadgeText = styled(Text1Center)<{ color: any }>`
  color: ${({ color }) => color};
  font-size: 11px;
`;
