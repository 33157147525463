type Action<T> = {
  type: string;
  payload: T;
  meta: any;
  error: any;
};

type Callbacks = {
  onSuccess: () => void;
  onError: () => void;
};

export enum ReminderTypes {
  Learning = "Learning",
}

export type Reminder = {
  id: string;
  daysOfWeek: number[];
  hour: number;
  utcHour: number;
  minute: number;
  timezone: string;
  userId: string;
  isActive: boolean;
  lastSent?: string;
  type: ReminderTypes;
};

export type ReminderPayload = {
  daysOfWeek: number[];
  hour: number;
  minute: number;
};

export type RemindersState = {
  data: Reminder[];
};

export type GetRemindersPayload = Callbacks;

export type AddReminderPayload = Callbacks & ReminderPayload;

export type DeleteReminderPayload = Callbacks & {
  id: string;
};

export type EditReminder = {
  id: string;
  daysOfWeek: number[];
  hour: number;
  minute: number;
};

export type EditReminderPayload = Callbacks & EditReminder;

export type GetRemindersAction = Action<GetRemindersPayload>;
export type AddReminderAction = Action<AddReminderPayload>;
export type EditReminderAction = Action<EditReminderPayload>;
export type DeleteReminderAction = Action<DeleteReminderPayload>;
