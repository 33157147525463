import * as React from "react";
import { ActivityIndicator, Alert } from "react-native";
import { PlatformPay, usePlatformPay } from "~/utils/stripe";

import { fetchPaymentIntentClientSecret } from "~/state/payments";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { PlatformPayment } from "~/state/payments/types";

import { Divider, DividerBox } from "../payment-details/styles";
import {
  Container,
  Row,
  PayButton,
  AppleIcon,
  GoogleIcon,
  ButtonContainer,
  ButtonText,
  DividerText,
  DividerTextBox,
  LoaderBox,
} from "./styles";
import { messages } from "./intl";
import { setPartialProfile } from "~/state/user";
import { Environment, Profile } from "~/state/user/types";
import { formatMessage } from "~/utils/translation";
import { isAndroid, isIOS } from "~/utils/platform";

interface ButtonProps {
  text: TextType;
  isActive?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onPress: () => void;
  flex?: number;
}

interface Props {
  amount: number;
  currency: string;
  isSubscription: boolean;
  onSuccess: () => void;
  onError: (error: any) => void;
}

const getPaymentConfig = (
  platform: PlatformPayment,
  currency: string,
  amount: number = 0,
  testEnv: boolean
) => {
  const currencyCode = currency.toUpperCase();
  const merchantCountryCode = "US";

  if (platform === PlatformPayment.APPLE) {
    return {
      applePay: {
        cartItems: [
          {
            label: "Bible Engagement Project",
            amount: amount.toFixed(2),
            paymentType: PlatformPay.PaymentType.Immediate,
          },
        ],
        merchantCountryCode,
        currencyCode,
        requiredBillingContactFields: [PlatformPay.ContactField.PhoneNumber],
      },
    };
  }

  return {
    googlePay: {
      testEnv,
      merchantName: "org.ag.bep",
      merchantCountryCode,
      currencyCode,
      billingAddressConfig: {
        format: PlatformPay.BillingAddressFormat.Full,
        isPhoneNumberRequired: true,
        isRequired: true,
      },
    },
  };
};

export const PayButtons = React.memo<Props>(
  ({ amount, currency, isSubscription, onSuccess, onError }) => {
    const [isApplePaySupported, setIsApplePaySupported] = React.useState(false);
    const [isGooglePaySupported, setIsGooglePaySupported] =
      React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const env = useAppSelector(getEnvironment);
    const uid = useAppSelector(getUserId);
    const dispatch = useAppDispatch();
    const { isPlatformPaySupported, confirmPlatformPayPayment } =
      usePlatformPay();

    const testEnv = env === Environment.Staging;

    const handleResult = (isSuccess: boolean) => {
      setIsLoading(false);
      if (isSuccess) {
        onSuccess();
        return;
      }
      onError("Payment has failed");
    };

    React.useEffect(() => {
      (async function () {
        const isSupported = await isPlatformPaySupported({
          googlePay: { testEnv },
        });
        if (isAndroid) {
          setIsGooglePaySupported(isSupported);
        }
        if (isIOS) {
          setIsApplePaySupported(isSupported);
        }
      })();
    }, [isPlatformPaySupported, testEnv]);

    const onPayment = async (platform: PlatformPayment) => {
      setIsLoading(true);
      const { clientSecret, customer } = await fetchPaymentIntentClientSecret({
        amount,
        currency,
        uid,
        isSubscription,
        env,
      });

      if (customer) {
        dispatch(
          setPartialProfile({ [`customerId_${env}`]: customer } as Profile)
        );
      }

      if (!clientSecret) {
        handleResult(false);
        return;
      }

      const { error } = await confirmPlatformPayPayment(
        clientSecret,
        getPaymentConfig(platform, currency, amount, testEnv)
      );

      if (error) {
        if (error?.localizedMessage) {
          Alert.alert(formatMessage(messages.error), error.localizedMessage);
        }

        handleResult(false);
        return;
      }
      handleResult(true);
    };

    if (!isApplePaySupported && !isGooglePaySupported) {
      return null;
    }

    return (
      <>
        <Container>
          <Row>
            {isApplePaySupported ? (
              <PayButton
                onPress={() => onPayment(PlatformPayment.APPLE)}
                isDisabled={isLoading}
                isLeft
              >
                <AppleIcon size={50} />
              </PayButton>
            ) : null}

            {isGooglePaySupported ? (
              <PayButton
                onPress={() => onPayment(PlatformPayment.GOOGLE)}
                isDisabled={isLoading}
                isRight
              >
                <GoogleIcon size={50} />
              </PayButton>
            ) : null}
          </Row>
        </Container>

        <DividerBox>
          <Divider />

          <DividerTextBox>
            <DividerText>{messages.or}</DividerText>
          </DividerTextBox>
        </DividerBox>
      </>
    );
  }
);

export const Button = React.memo<ButtonProps>(
  ({ text, isActive, isDisabled, isLoading, onPress, flex }) => {
    const handlePress = () => {
      if (isDisabled) {
        return;
      }
      onPress();
    };

    return (
      <ButtonContainer
        isActive={isActive}
        onPress={handlePress}
        flex={flex}
        disabled={isDisabled}
        isLoading={isLoading}
      >
        <ButtonText isActive={isActive}>{text}</ButtonText>
        {isLoading ? (
          <LoaderBox>
            <ActivityIndicator color="white" />
          </LoaderBox>
        ) : null}
      </ButtonContainer>
    );
  }
);
