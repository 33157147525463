import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AnnouncementsState, Announcement, EditAnnouncement } from "./types";

const initialState: AnnouncementsState = {
  data: [],
};

export const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    updateAnnouncements: (state, action: PayloadAction<Announcement[]>) => {
      state.data = action.payload;
    },
    addAnnouncement: (state, action: PayloadAction<Announcement>) => {
      state.data = [...state.data, action.payload];
    },
    deleteAnnouncement: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter(({ id }) => id !== action.payload);
    },
    editAnnouncement: (state, action: PayloadAction<EditAnnouncement>) => {
      state.data = state.data.map((announcement) => {
        if (announcement.id !== action.payload.id) {
          return announcement;
        }
        return {
          ...announcement,
          ...action.payload,
        };
      });
    },
  },
});

export const {
  updateAnnouncements,
  addAnnouncement,
  deleteAnnouncement,
  editAnnouncement,
} = announcementsSlice.actions;

export default announcementsSlice.reducer;
