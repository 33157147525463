import React from "react";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { SettingsStackParamList } from "~/navigation/settings-stack";
import {
  SettingsAvatar,
  SettingsListItem,
  SettingsListItemContainer,
  SettingsListItemDivider,
  SettingsListItemLabel,
  SettingsScreen,
} from "~/components/settings";
import { ModalHeader } from "~/components/modal-header";
import { Alerts } from "~/components/alerts";
import { formatMessage } from "~/utils/translation";
import { useAppDispatch, useAppNetInfo, useAppSelector } from "~/state/hooks";
import {
  getUserEmail,
  getFullName,
  getEmailOptIn,
  getIsSocialProvider,
} from "~/state/user/selectors";
import { editProfile } from "~/state/user/actions";
import { OptInStatus } from "~/state/user/types";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";
import * as routes from "~/constants/routes";
import { genericMessages } from "~/constants/intl";
import { auth } from "<config>/firebase";
import { ChangeNameComponent } from "~/screens/change-name";
import { ChangeEmailComponent } from "~/screens/change-email";
import { isWeb } from "~/utils/platform";
import { useModal } from "~/state/modal/hook";
import { useAlert } from "~/components/alert";

import { EditPassword } from "./components/edit-password";
import {
  DeleteAccountButton,
  DeleteAccountButtonContainer,
  DeleteAccountButtonIcon,
  DeleteAccountButtonText,
} from "./styles";
import { messages } from "./intl";

export type ProfileProps = NativeStackScreenProps<
  SettingsStackParamList,
  typeof routes.profile
>;

export const Profile = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = React.useState(false);
  const navigation = useNavigation<ProfileProps["navigation"]>();
  const userEmail = useAppSelector(getUserEmail);
  const fullName = useAppSelector(getFullName);
  const emailOptIn = useAppSelector(getEmailOptIn);
  const isSocialProvider = useAppSelector(getIsSocialProvider);
  const dispatch = useAppDispatch();
  const { isConnected } = useAppNetInfo();
  const { pushAlert } = useAlerts();
  const { showModal, hideModal } = useModal();
  const { showAlert } = useAlert();

  const onDonePress = React.useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const handleOffline = React.useCallback(() => {
    pushAlert({
      message: genericMessages.featureUnavailableOffline,
      color: colors.gray600,
    });
  }, [pushAlert]);

  const onEditNamePress = React.useCallback(() => {
    if (!isConnected) {
      handleOffline();

      return;
    }

    if (!isWeb) {
      navigation.navigate(routes.changeName);
    } else {
      showModal(<ChangeNameComponent callback={hideModal} />);
    }
  }, [handleOffline, isConnected, navigation, showModal, hideModal]);

  const onEditEmailPress = React.useCallback(() => {
    if (!isConnected) {
      handleOffline();

      return;
    }

    if (isSocialProvider) {
      return;
    }

    if (!isWeb) {
      navigation.navigate(routes.changeEmail);
    } else {
      showModal(<ChangeEmailComponent callback={hideModal} />);
    }
  }, [
    handleOffline,
    isConnected,
    navigation,
    isSocialProvider,
    showModal,
    hideModal,
  ]);

  const onEditPasswordPress = React.useCallback(async () => {
    if (!isConnected) {
      handleOffline();

      return;
    }

    navigation.navigate(routes.changePassword);
  }, [handleOffline, isConnected, navigation]);

  const onOptOutPress = React.useCallback(async () => {
    if (!isConnected) {
      handleOffline();

      return;
    }

    setIsLoading(true);
    dispatch(
      editProfile({
        emailOptIn:
          emailOptIn === OptInStatus.subscribed
            ? OptInStatus.pending
            : OptInStatus.subscribed,
        onSuccess: () => setIsLoading(false),
        onError: () => setIsLoading(false),
      })
    );
  }, [dispatch, emailOptIn, handleOffline, isConnected]);

  // if the user is logged in with a social provider, we don't need to show this option
  const shouldDisplayChangePassword = React.useMemo(() => {
    const hasPasswordProvider = (auth.currentUser?.providerData ?? []).some(
      (provider) => provider.providerId === "password"
    );

    return hasPasswordProvider;
  }, []);

  const nameTitle = fullName || formatMessage(messages.namePlaceholder);

  const onDeleteAccountPress = React.useCallback(() => {
    if (isDeletingAccount) {
      return;
    }
    setIsDeletingAccount(true);

    showAlert(
      formatMessage(messages.deleteAccountConfirmation),
      formatMessage(messages.deleteAccountConfirmationDescription),
      [
        {
          text: formatMessage(messages.deleteAccountConfirmationCancel),
          onPress: () => setIsDeletingAccount(false),
          style: "cancel",
        },
        {
          text: formatMessage(messages.deleteAccountConfirmationDelete),
          onPress: () => {
            navigation.navigate(routes.deleteAccount);
            setIsDeletingAccount(false);
          },
          style: "destructive",
        },
      ]
    );
  }, [navigation, isDeletingAccount, showAlert]);

  return (
    <SettingsScreen screenName="profile">
      {!isWeb ? (
        <ModalHeader
          onLeftPress={onDonePress}
          onRightPress={onDonePress}
          title={messages.title}
        />
      ) : null}

      <SettingsAvatar allowEdition />

      <SettingsListItemContainer>
        <SettingsListItemLabel label={formatMessage(messages.contactInfo)} />

        <SettingsListItem
          title={nameTitle}
          type="edit"
          isFirstItem
          onPress={onEditNamePress}
          isPlaceholder={!fullName}
        />

        <SettingsListItemDivider />

        <SettingsListItem
          title={userEmail}
          type={!isSocialProvider ? "edit" : undefined}
          isLastItem
          onPress={onEditEmailPress}
        />
      </SettingsListItemContainer>

      {shouldDisplayChangePassword && (
        <SettingsListItemContainer>
          <SettingsListItemLabel label={formatMessage(messages.password)} />

          <EditPassword handleOffline={handleOffline} />
        </SettingsListItemContainer>
      )}

      <SettingsListItemContainer>
        <SettingsListItemLabel label={formatMessage(messages.emailSettings)} />

        <SettingsListItem
          title={formatMessage(messages.emailOptOut)}
          type="checkbox"
          isFirstItem
          isLastItem
          isLoading={isLoading}
          onPress={onOptOutPress}
          isActive={emailOptIn === OptInStatus.subscribed}
        />
      </SettingsListItemContainer>

      <DeleteAccountButtonContainer>
        <DeleteAccountButton onPress={onDeleteAccountPress}>
          <DeleteAccountButtonIcon />

          <DeleteAccountButtonText>
            {formatMessage(messages.deleteAccount)}
          </DeleteAccountButtonText>
        </DeleteAccountButton>
      </DeleteAccountButtonContainer>

      <Alerts />
    </SettingsScreen>
  );
};
