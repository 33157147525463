import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { genericMessages } from "~/constants/intl";
import { AuthScreen } from "~/components/auth-screen";
import { InputBox } from "~/components/auth-screen/styles";
import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { TextInput } from "~/components/text-input";
import { useAppDispatch } from "~/state/hooks";
import { updatePassword } from "~/state/user/actions";
import { isValidPassword } from "~/utils/strings";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { Container, Message } from "./styles";
import { messages } from "./intl";
import { messages as generalMessages } from "../sign-up/intl";

interface Props {
  code: string;
}

export const ResetPassword = React.memo<Props>(({ code }) => {
  const [password, setPassword] = React.useState("");
  const [passwordRepeat, setPasswordRepeat] = React.useState("");
  const [passwordError, setPasswordError] = React.useState<MessageDescriptor>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasBeenReset, setHasBeenReset] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { pushAlert } = useAlerts();

  const isValid = isValidPassword(password) && password === passwordRepeat;

  const resetPasswordError = React.useCallback(
    () => setPasswordError(undefined),
    []
  );

  const handleError = React.useCallback((error: string = "") => {
    if (error.includes("auth/invalid-action-code")) {
      pushAlert({ message: messages.errorInvalid, color: colors.black });
      return;
    }
    if (error.includes("auth/user-not-found")) {
      setPasswordError(error);
      return;
    }
    if (error.includes("auth/weak-password")) {
      setPasswordError(generalMessages.weakPassword);
      return;
    }

    pushAlert({ message: genericMessages.error, color: colors.black });
  }, []);

  const handlePress = React.useCallback(() => {
    setPasswordError(undefined);
    setIsLoading(true);

    dispatch(
      updatePassword({
        password,
        code,
        onSuccess: () => {
          setIsLoading(false);
          setHasBeenReset(true);
        },
        onError: (e) => {
          handleError(e);
          setIsLoading(false);
        },
      })
    );
  }, [dispatch, password, code, handleError]);

  const navigateBackToLogin = React.useCallback(
    () => navigation.navigate(routes.login),
    [navigation]
  );

  return (
    <AuthScreen
      screenName="reset-password"
      title={messages.title}
      hasBackButton
      id="forgot-password"
    >
      {!hasBeenReset ? (
        <>
          <InputBox>
            <TextInput
              label={messages.password}
              value={password}
              onChangeText={setPassword}
              onFocus={resetPasswordError}
              errorMessage={passwordError}
              secureTextEntry
              isSecure
            />

            <TextInput
              label={messages.passwordRepeat}
              value={passwordRepeat}
              onChangeText={setPasswordRepeat}
              onFocus={resetPasswordError}
              hintMessage={generalMessages.hintPassword}
              secureTextEntry
              isSecure
            />
          </InputBox>

          <Button
            text={messages.button}
            onPress={handlePress}
            type={ButtonTypes.Primary}
            isDisabled={!isValid}
            isLoading={isLoading}
            isUppercase
          />
        </>
      ) : (
        <>
          <Container>
            <Message>{messages.success}</Message>
          </Container>
          <Button
            text={messages.backToLogin}
            onPress={navigateBackToLogin}
            type={ButtonTypes.Primary}
            isUppercase
          />
        </>
      )}
    </AuthScreen>
  );
});
