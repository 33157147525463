export const messages = {
  inactive: {
    id: "status-tag.inactive",
    defaultMessage: "Inactive",
  },
  active: {
    id: "status-tag.active",
    defaultMessage: "Active",
  },
  expired: {
    id: "status-tag.expired",
    defaultMessage: "Expired",
  },
};
