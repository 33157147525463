import React from "react";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { ListButton } from "~/components/button";

import { AccountHeader } from "../../../header";
import { Container, Title, Section, SectionTitle } from "../../styles";
import { messages as generalMessages } from "../../intl";
import { Screens } from "../../../../types";
import { navigateToFAQ } from "../../../utils";

import { Description } from "./styles";
import { messages } from "./intl";

interface Props {
  onBackPress: () => void;
  setScreen: (screen: Screens) => void;
}

export const GivingIntro = React.memo<Props>(({ setScreen, onBackPress }) => {
  const navigation = useNavigation();

  const handleContact = React.useCallback(() => {
    navigation.goBack();
    setTimeout(() => {
      navigation.navigate(routes.helpAndFeedback);
    }, 1000);
  }, [navigation]);

  return (
    <Container>
      <AccountHeader onBackPress={onBackPress} />

      <Title>{generalMessages.giving}</Title>

      <Description>{messages.description}</Description>

      <Section>
        <SectionTitle>{messages.info}</SectionTitle>

        <ListButton
          text={generalMessages.history}
          onPress={() => setScreen(Screens.History)}
        />
        <ListButton
          text={generalMessages.manage}
          onPress={() => setScreen(Screens.Manage)}
        />
        <ListButton
          text={generalMessages.taxReceipts}
          onPress={() => setScreen(Screens.TaxReceipts)}
        />
      </Section>
      <Section>
        <SectionTitle>{messages.help}</SectionTitle>

        <ListButton text={generalMessages.faq} onPress={navigateToFAQ} />
        <ListButton text={generalMessages.contact} onPress={handleContact} />
      </Section>
    </Container>
  );
});
