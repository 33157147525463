export const messages = {
  error: {
    id: "flamelink-saga.download-error",
    defaultMessage: "An error occurred while downloading the files",
  },
  filesDownload: {
    id: "downloads.files-download",
    defaultMessage: "{{input}}/{{output}} files ({{percentage}}%)",
  },
};
