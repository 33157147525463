export const messages = {
  scheduleTimespan: {
    id: "content.schedule-timespan",
    defaultMessage: "Send at {{from}}",
  },
  empty: {
    id: "content.empty",
    defaultMessage: "No results found",
  },
  createNew: {
    id: "content.create-new",
    defaultMessage: "Create new announcement",
  },
};
