import * as React from "react";
import { RadioButton, Switch } from "react-native-paper";

import { TextInput } from "~/components/text-input";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  getInAppNotificationById,
  NotificationType,
  LinkType,
} from "~/state/in-app-notifications-setup";
import {
  addInAppNotification,
  removeInAppNotification,
  editInAppNotification,
} from "~/state/in-app-notifications-setup/actions";
import { ActionButtons } from "~/components/cms/action-buttons";
import { CharacterCount } from "~/components/cms/character-count";
import {
  Container,
  InputBox,
  Label,
  InputSection,
  SectionTitle,
  HeaderBox,
  dateInputStyle,
  Disclaimer,
  FormImage,
  RadioOptions,
  RadioOption,
  RadioText,
  InfoIcon,
  LabelRow,
  InputSectionRelative,
} from "~/components/cms/styles";
import { useAlerts } from "~/state/alerts";
import { genericMessages } from "~/constants/intl";
import { colors } from "~/styles/theme";

import { messages } from "./intl";
import { messages as sectionMessages } from "../../intl";
import { Options } from "../options";
import { getRouteOptions } from "~/utils/strings";
import { Select } from "../select";
import { formatMessage } from "~/utils/translation";

interface Props {
  id?: string;
  onBack: () => void;
}

const MAX_TITLE_LENGTH = 160;
const MAX_DESCRIPTION_LENGTH = 2000;
const IMAGE_SIZE_LIMIT = 300 * 1024; // 300KB

export const AnnouncementForm = React.memo<Props>(({ id = "", onBack }) => {
  const data = useAppSelector((state) => getInAppNotificationById(state, id));

  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  const defaultRoute = data?.route || "";
  const defaultUrl = data?.url || "";
  const defaultNavigateTo = data?.navigateTo || "";
  const defaultLinkType = data?.linkType || LinkType.INTERNAL;

  const [type, setType] = React.useState(NotificationType.MODAL);
  const [title, setTile] = React.useState(data?.title || "");
  const [description, setDescription] = React.useState(data?.description || "");
  const [schedule, setSchedule] = React.useState(data?.schedule || "");
  const [expiration, setExpiration] = React.useState(data?.expiration || "");
  const [image, setImage] = React.useState<File | undefined>(undefined);
  const [imageError, setImageError] = React.useState("");
  const [route, setRoute] = React.useState(data?.route || "");
  const [linkType, setLinkType] = React.useState(defaultLinkType);
  const [navigateTo, setNavigateTo] = React.useState(defaultNavigateTo);
  const [url, setUrl] = React.useState(data?.url || "");
  const [buttonText, setButtonText] = React.useState(data?.buttonText || "");
  const [options, setOptions] = React.useState(data?.options || {});
  const [isActive, setIsActive] = React.useState(!!data?.isActive);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const isModal = type === NotificationType.MODAL;
  const isBanner = type === NotificationType.BANNER;

  const isInitialState =
    type === data?.type &&
    title === data?.title &&
    description === data?.description &&
    schedule === data?.schedule &&
    expiration === data?.expiration &&
    route === defaultRoute &&
    navigateTo === defaultNavigateTo &&
    url === defaultUrl &&
    buttonText === data?.buttonText &&
    isActive === data?.isActive &&
    linkType === defaultLinkType &&
    !image;

  const isModalRequired = isModal && (!title || !route || !buttonText);

  const isButtonDisabled =
    isInitialState ||
    isSaving ||
    isModalRequired ||
    !description ||
    !schedule ||
    !expiration ||
    (linkType === LinkType.INTERNAL && !navigateTo) ||
    (linkType === LinkType.EXTERNAL && !url);

  const sectionTitle = id ? sectionMessages.edit : sectionMessages.create;

  const handleSetTitle = React.useCallback(
    (titleValue: string) => {
      if (titleValue.length <= MAX_TITLE_LENGTH) {
        setTile(titleValue);
      }
    },
    [setTile]
  );

  const handleSetBody = React.useCallback(
    (descriptionValue: string) => {
      if (descriptionValue.length <= MAX_DESCRIPTION_LENGTH) {
        setDescription(descriptionValue);
      }
    },
    [setDescription]
  );

  const onSave = React.useCallback(() => {
    setIsSaving(true);

    const dataToSave = {
      type,
      title,
      description,
      schedule,
      expiration,
      image,
      route,
      linkType,
      navigateTo,
      url,
      buttonText,
      isActive,
      options,
    };

    if (!id) {
      dispatch(
        addInAppNotification({
          ...dataToSave,
          onSuccess: () => {
            setIsSaving(false);
            pushAlert({
              message: genericMessages.saveSuccess,
              color: colors.emerald600,
            });
            onBack();
          },
          onError: () => {
            setIsSaving(false);
            pushAlert({
              message: genericMessages.error,
            });
            onBack();
          },
        })
      );
      return;
    }

    dispatch(
      editInAppNotification({
        id,
        ...dataToSave,
        onSuccess: () => {
          setIsSaving(false);
          pushAlert({
            message: genericMessages.saveSuccess,
            color: colors.emerald600,
          });
          onBack();
        },
        onError: () => {
          setIsSaving(false);
          pushAlert({
            message: genericMessages.error,
          });
          onBack();
        },
      })
    );
  }, [
    id,
    type,
    title,
    description,
    schedule,
    expiration,
    buttonText,
    image,
    route,
    linkType,
    navigateTo,
    url,
    isActive,
    options,
    dispatch,
    onBack,
    pushAlert,
  ]);

  const onDelete = () => {
    if (!id) {
      return;
    }
    setIsDeleting(true);
    dispatch(
      removeInAppNotification({
        id,
        onSuccess: () => {
          setIsDeleting(false);
          pushAlert({
            message: genericMessages.itemDeleted,
            color: colors.emerald600,
          });
          onBack();
        },
        onError: () => {
          setIsDeleting(false);
          pushAlert({
            message: genericMessages.error,
          });
          onBack();
        },
      })
    );
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      if (selectedFile.size > IMAGE_SIZE_LIMIT) {
        setImageError("File size exceeds 300KB");
        setImage(undefined);
      } else {
        setImage(selectedFile);
        setImageError("");
      }
    }
  };

  const displayImage = image ? URL.createObjectURL(image) : data?.imageUrl;

  return (
    <Container>
      <HeaderBox>
        <SectionTitle>{sectionTitle}</SectionTitle>
      </HeaderBox>

      <InputBox>
        <Label>{messages.enabled}</Label>
        <Switch value={isActive} onValueChange={setIsActive} />
      </InputBox>

      <>
        <Label>{messages.type}</Label>
        <RadioButton.Group
          onValueChange={(value) => setType(value as NotificationType)}
          value={type}
        >
          <RadioOptions>
            <RadioOption>
              <RadioButton value={NotificationType.MODAL} />
              <RadioText>{messages.typeModal}</RadioText>
            </RadioOption>
            <RadioOption>
              <RadioButton value={NotificationType.BANNER} />
              <RadioText>{messages.typeBanner}</RadioText>
            </RadioOption>
          </RadioOptions>
        </RadioButton.Group>
      </>

      {!isBanner ? (
        <>
          <TextInput
            label={messages.title}
            onChangeText={handleSetTitle}
            value={title}
          />
          <CharacterCount text={title} limit={MAX_TITLE_LENGTH} />
        </>
      ) : null}

      <TextInput
        label={messages.description}
        onChangeText={handleSetBody}
        value={description}
      />
      <CharacterCount text={description} limit={MAX_DESCRIPTION_LENGTH} />

      {!isBanner ? (
        <TextInput
          label={messages.buttonText}
          onChangeText={setButtonText}
          value={buttonText}
        />
      ) : null}

      <InputSectionRelative>
        <Label>{messages.buttonLink}</Label>

        <RadioButton.Group
          onValueChange={(value) => setLinkType(value as LinkType)}
          value={linkType}
        >
          <RadioOptions>
            <RadioOption>
              <RadioButton value={LinkType.INTERNAL} />
              <RadioText>{messages.internalLink}</RadioText>
            </RadioOption>
            <RadioOption>
              <RadioButton value={LinkType.EXTERNAL} />
              <RadioText>{messages.externalLink}</RadioText>
            </RadioOption>
          </RadioOptions>
        </RadioButton.Group>

        {linkType === LinkType.INTERNAL ? (
          <Select
            label={messages.buttonLinkScreen}
            options={getRouteOptions()}
            setOption={setNavigateTo}
            value={navigateTo}
          />
        ) : (
          <TextInput
            label={messages.buttonLinkUrl}
            onChangeText={setUrl}
            value={url}
          />
        )}

        <Disclaimer>{messages.buttonLinkDisclaimer}</Disclaimer>
      </InputSectionRelative>

      {!isBanner ? (
        <>
          <Select
            label={messages.screen}
            options={getRouteOptions()}
            setOption={setRoute}
            value={route}
          />
          <Disclaimer>{messages.screenDisclaimer}</Disclaimer>
        </>
      ) : null}

      <InputSection>
        <Label>{messages.schedule}</Label>
        <input
          type="datetime-local"
          onChange={(e) => setSchedule(e.target.value)}
          value={schedule}
          style={dateInputStyle}
        />
      </InputSection>

      <InputSection>
        <Label>{messages.expiration}</Label>
        <input
          type="datetime-local"
          onChange={(e) => setExpiration(e.target.value)}
          value={expiration}
          style={dateInputStyle}
        />
      </InputSection>

      {!isBanner ? (
        <>
          <InputSection>
            <Label>{messages.image}</Label>

            {displayImage ? <FormImage source={{ uri: displayImage }} /> : null}
            <input
              type="file"
              onChange={handleImageChange}
              style={dateInputStyle}
            />
          </InputSection>
          {imageError ? <Disclaimer isError>{imageError}</Disclaimer> : null}
        </>
      ) : null}

      {!isBanner ? (
        <>
          <InputSection>
            <div title={formatMessage(messages.optionsTooltip)}>
              <LabelRow>
                <Label>{messages.options}</Label>

                <InfoIcon />
              </LabelRow>
            </div>

            <Options options={options} setOptions={setOptions} />
          </InputSection>
          <Disclaimer>{messages.optionsDisclaimer}</Disclaimer>
        </>
      ) : null}

      <ActionButtons
        onCancel={onBack}
        onSave={onSave}
        onDelete={onDelete}
        isSaveDisabled={isButtonDisabled}
        isDeleteDisabled={isDeleting}
        hideDelete={!id}
      />
    </Container>
  );
});
