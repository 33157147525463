import JSZip from "jszip";
import { saveAs } from "file-saver";

import { FlamelinkFile, makeDownloadUrl } from "~/state/flamelink";
import { logError } from "~/utils/logger";

export const downloadFilesAsZip = async (
  fileData: FlamelinkFile[],
  bundleName: string,
  callback: () => void
) => {
  const zip = new JSZip();

  const fetchPromises = fileData.map(async (data) => {
    const fileName =
      data.file?.file || data.fileHD?.file || data.fileUltraHD?.file;

    try {
      if (!fileName) {
        return;
      }

      const response = await fetch(makeDownloadUrl(fileName));
      if (!response.ok) {
        throw new Error(`Failed to fetch file ${fileName}`);
      }

      const blob = await response.blob();
      zip.file(fileName, blob);
    } catch (e) {
      logError(`Error fetching file ${fileName}: ${e}`);
    }
  });

  Promise.all(fetchPromises)
    .then(() => {
      zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, `${bundleName}.zip`);
        callback();
      });
    })
    .catch((error) => {
      console.error("Error generating zip file:", error);
      callback();
    });
};
