import { MessageType } from "~/state/chat/types";

export enum Roles {
  Member = "member",
  Leader = "leader",
  Admin = "admin",
}

type Action<T> = {
  type: string;
  payload: T;
  meta: any;
  error: any;
};

export type Member = {
  id: string;
  role: Roles;
  lastActivity: number;
};

export type Group = {
  id: string;
  name: string;
  subscribers: string[];
  members: Member[];
  plans: string[];
  imageUri?: string;
  inviteCode?: string;
  createdAt: number;
  timestamp: number;
  totalMessages: number;
  recentMessage: {
    content: string;
    type: MessageType;
    sentBy: string;
    sentAt: number;
    id: string;
    isDeleted: boolean;
  };
  readMessages: {
    [id: string]: number;
  };
};

export type GroupsState = {
  data: Group[];
  inviteCode: string;
};

export type GroupMessage = {
  senderName: string;
  text: string;
  groupId: string;
  groupName: string;
  groupUri: string;
  timestamp: number;
  isCurrentUser: boolean;
  isRead: boolean;
  isDeleted: boolean;
  type: MessageType;
};

type Callbacks = {
  onSuccess?: () => void;
  onError?: () => void;
};

export type CreateGroupPayload = {
  name?: string;
  imageUri?: string;
  planId?: string;
  onSuccess: (groupId: string) => void;
  onError: () => void;
};

export type CreateGroupAction = Action<CreateGroupPayload>;

export type EditGroupPayload = {
  groupId: string;
  name?: string;
  imageUri?: string;
  onSuccess: (groupId: string) => void;
  onError: () => void;
};

export type EditGroupAction = Action<EditGroupPayload>;

export type JoinGroupPayload = {
  inviteCode: string;
  userId?: string;
  onSuccess?: (groupId: string) => void;
  onError?: (message: TextType) => void;
};

export type JoinGroupAction = Action<JoinGroupPayload>;

export type AddResourcePayload = Callbacks & {
  groupId: string;
  planId: string;
};

export type AddResourceAction = Action<AddResourcePayload>;

export type RemoveResourcePayload = Callbacks & {
  groupId: string;
  planId: string;
};

export type RemoveResourceAction = Action<RemoveResourcePayload>;

export type RemoveMemberPayload = Callbacks & {
  groupId: string;
  userId: string;
};

export type RemoveMemberAction = Action<RemoveMemberPayload>;

export type ChangeRolePayload = Callbacks & {
  groupId: string;
  userId: string;
  isLeader: boolean;
};

export type ChangeRoleAction = Action<ChangeRolePayload>;

export type SetLastActivityPayload = {
  groupId: string;
};

export type SetLastActivityAction = Action<SetLastActivityPayload>;

export type DeleteGroupPayload = Callbacks & {
  groupId: string;
};

export type DeleteGroupAction = Action<DeleteGroupPayload>;

export type RefreshInvitecodePayload = Callbacks & {
  groupId: string;
};

export type RefreshInvitecodeAction = Action<RefreshInvitecodePayload>;
