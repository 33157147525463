export const messages = {
  title: {
    id: "announcements.title",
    defaultMessage: "Announcements",
  },
  subtitle: {
    id: "announcements.subtitle",
    defaultMessage: "Push Notification",
  },
  create: {
    id: "announcements.create",
    defaultMessage: "Create Announcement",
  },
  edit: {
    id: "announcements.edit",
    defaultMessage: "Edit Announcement",
  },
};
