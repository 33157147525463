export const login = "login";
export const signUp = "sign-up";
export const intro = "intro";
export const forgotPassword = "forgot-password";
export const resetPassword = "reset-password";
export const createProfile = "create-profile";
export const locationModal = "choose-location";
export const prefixModal = "choose-prefix";
export const imageModal = "choose-image";
export const payItForward = "pay-it-forward";
export const giving = "giving";
export const inviteCode = "i"; // It's for the purpose of a short URL on web
export const auth = "__"; // It's for the purpose of the Firebase redirects

// ==== Home Navigator ====
export const home = "home";
export const homeTab = "home-tab";

// ==== Library Navigator ====
export const library = "library";
export const libraryTab = "library-tab";
export const librarySearch = "library-search";
export const libraryFilters = "library-filters";
export const libraryProgress = "library-progress";
export const plan = "plan";
export const session = "session";
export const sessionDetails = "session-details";
export const completeDay = "complete-day";
export const videoPlayer = "video-player";
export const sessionModal = "session-modal";

// ==== Bible Navigator ====
export const bible = "bible";
export const bibleTab = "bible-tab";
export const booksModal = "bible-books";
export const versionModal = "bible-version";

// ==== Notes Navigator ====
export const notes = "notes";
export const notesTab = "notes-tab";
export const sessionNotes = "session-notes";
export const bibleNotes = "bible-notes";
export const sessionNotesView = "session-notes-view";
export const sessionNoteView = "session-note-view";
export const notesMigrated = "notes-migrated";
export const bibleNoteAdd = "bible-notes-add";
export const bibleNoteViewEdit = "bible-notes-view";
export const personalNotes = "personal-notes";
export const personalNotesAdd = "personal-notes-add";
export const personalNotesViewEdit = "personal-notes-view";

// ==== Groups Navigator ====
export const groups = "groups";
export const groupsTab = "groups-tab";
export const groupResources = "group-resources";
export const groupJoin = "group-join";
export const groupModal = "group-details";
export const groupChat = "chat";

export const messages = "messages";

// ==== Settings Navigator ====
export const settingsModal = "settings-all";
export const settings = "settings";
export const appSettings = "app-settings";
export const profile = "profile";
export const about = "about";
export const helpAndFeedback = "help-and-feedback";
export const downloads = "downloads";
export const changeName = "change-name";
export const changeEmail = "change-email";
export const changePassword = "change-password";
export const deleteAccount = "delete-account";
export const devTools = "dev-tools";
export const reminders = "reminders";
export const appFeedback = "app-feedback";
export const legal = "legal";
export const announcements = "announcements";
export const inAppMessages = "in-app-messages";

export const cookiePolicy = "cookie-policy";
