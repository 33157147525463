import styled from "styled-components/native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { Text1, Text2, Text5 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

export const dateInputStyle = {
  borderColor: "transparent",
  paddingTop: 8,
  paddingBottom: 14,
  paddingLeft: 10,
  fontSize: 16,
  fontFamily: "Roboto",
  borderBottom: `1px solid ${colors.gray400}`,
};

export const Container = styled.View``;

export const List = styled.FlatList`
  border-radius: 8px;
  overflow: hidden;
  margin-vertical: ${spacers.ss8};
`;

export const SectionSubTitle = styled(Text2)``;

export const ListItem = styled.TouchableOpacity<{ isLast?: boolean }>`
  border-bottom: 1px solid
    ${({ isLast }) => (isLast ? colors.transparent : colors.gray500)};
  padding: ${spacers.ss5} ${spacers.ss6};
  background-color: ${colors.gray50};
  flex-direction: row;
  align-items: center;
`;

export const HeaderBox = styled.View`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: ${spacers.ss9};
  margin-horizontal: ${spacers.ss4};
`;

export const AddIcon = styled(Icon).attrs({
  name: "plus",
  color: colors.primaryBlue,
  size: 16,
})``;

export const InfoIcon = styled(Icon).attrs({
  name: "message-question-outline",
  color: colors.gray500,
  size: 14,
})``;

export const SectionTitle = styled(Text5)`
  font-family: SFProDisplayMedium;
`;

export const Title = styled(Text2)`
  font-family: SFProDisplayMedium;
  margin-bottom: ${spacers.ss3};
`;

export const Meta = styled(Text1)`
  color: ${colors.gray500};
`;

export const InputBox = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-vertical: ${spacers.ss4};
  margin-top: ${spacers.ss6};
`;

export const ContentBox = styled.View`
  flex: 1;
`;

export const ActiveBox = styled.View``;

export const Label = styled(Text2)`
  font-family: Roboto;
  font-size: 12px;
  padding-left: ${spacers.ss6};
  padding-right: ${spacers.ss4};
  color: ${colors.gray600};
`;

export const LabelRow = styled.View`
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const InputSectionRelative = styled.View`
  margin-top: ${spacers.ss6};
  z-index: 99999;
`;

export const InputSection = styled.View`
  margin-top: ${spacers.ss6};
`;

export const Disclaimer = styled(Text1)<{ isError?: boolean }>`
  margin: ${spacers.ss2} ${spacers.ss5} ${spacers.ss4};
  color: ${({ isError }) => (isError ? colors.red600 : colors.gray400)};
`;

export const EmptyBox = styled.View`
  align-items: center;
  justify-content: center;
  padding-vertical: ${spacers.ss12};
`;

export const EmptyText = styled(Text2)``;

export const FormImage = styled.Image`
  height: 80px;
  width: 80px;
  margin: ${spacers.ss4} ${spacers.ss6};
`;

export const RadioOptions = styled.View`
  margin-top: ${spacers.ss4};
`;

export const RadioOption = styled.View`
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin-left: ${spacers.ss4};
`;

export const RadioText = styled(Text2)`
  margin-left: ${spacers.ss4};
`;
