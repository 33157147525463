import * as React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";

import { useAppSelector } from "~/state/hooks";
import { Alerts } from "~/components/alerts";
import { Switch } from "~/components/switch";
import { Screen } from "~/components/screen";
import { HeaderBar } from "~/components/header-bar";
import { isWeb } from "~/utils/platform";
import { getPaymentCurrencyLabel } from "~/state/payments";
import { getUser } from "~/state/user/selectors";
import { getUserFirstName } from "~/state/user/selectors";

import { Header } from "./components/header";
import { Content } from "./components/content";
import { AmountSelection } from "./components/amount";
import { PaymentDetails } from "./components/payment-details";
import { PaymentSuccess } from "./components/payment-success";

import { GivingIntro } from "./components/giving/components/intro";
import { GivingHistory } from "./components/giving/components/history";
import { GivingManage } from "./components/giving/components/manage";
import { TaxReceipts } from "./components/giving/components/tax-receipts";
import { PaymentMethod } from "./components/giving/components/payment-method";
import { PaymentAdd } from "./components/giving/components/payment-add";

import { Container } from "./styles";
import { Frequency, Screens } from "./types";
import { messages } from "./intl";

interface Props {
  isPreAuth?: boolean;
}

export const PayItForward = React.memo<Props>(({ isPreAuth }) => {
  const route = useRoute<any>();
  const defaultAmount = Number(route?.params?.amount) || 0;
  const isOneTime =
    route?.params?.type === Frequency.OneTime || Number(route?.params?.index);
  const defaultIndex = isOneTime ? 1 : 0;
  const defaultStep = !!defaultAmount
    ? Screens.PaymentDetails
    : route?.params?.step || Screens.AmountSelection;
  const needsRedirect = route?.params?.redirect_status === "succeeded";
  const defaultFirstName = useAppSelector(getUserFirstName);

  const [activeIndex, setActiveIndex] = React.useState(defaultIndex);
  const [amount, setAmount] = React.useState(defaultAmount);
  const [step, setStep] = React.useState(defaultStep);
  const [isCustomAmount, setCustomAmount] = React.useState(false);
  const [isEditingAmount, setIsEditingAmount] = React.useState(false);
  const [firstName, setFirstName] = React.useState(defaultFirstName);
  const currencyLabel = useAppSelector(getPaymentCurrencyLabel);
  const isMonthly = activeIndex === 0 && !isPreAuth;

  const navigation = useNavigation<any>();

  const backToIntro = () => {
    setStep(Screens.Intro);
  };

  const handleAmountChange = (value: number) => {
    setAmount(value);
    setIsEditingAmount(false);
  };

  const handleNextStep = React.useCallback(
    (nextStep: Screens) => {
      setStep(nextStep);
      navigation?.setParams({ redirect_status: undefined });
      navigation?.setParams({ step: nextStep });
    },
    [navigation]
  );

  React.useEffect(() => {
    if (!isWeb) {
      return;
    }

    if (needsRedirect) {
      handleNextStep(Screens.Success);
    }
  }, [needsRedirect, handleNextStep]);

  const hideHeaderBackButton =
    step === Screens.Success || (step === Screens.AmountSelection && isWeb);

  const switchOptions = [
    { message: messages.monthly },
    { message: messages.oneTime },
  ];

  return (
    <Container>
      <>
        {[
          Screens.AmountSelection,
          Screens.PaymentDetails,
          Screens.Success,
        ].includes(step) ? (
          <>
            <Header
              step={step}
              setStep={handleNextStep}
              hideBackButton={hideHeaderBackButton}
              hideAccountButton={isPreAuth}
            />
            {step !== Screens.Success && !isPreAuth ? (
              <Switch
                activeIndex={activeIndex}
                setIndex={setActiveIndex}
                options={switchOptions}
              />
            ) : null}
          </>
        ) : null}

        {step === Screens.AmountSelection ? (
          <>
            <Content />

            <AmountSelection
              amount={amount}
              setAmount={(value: number, isCustom: boolean) => {
                handleAmountChange(value);
                setCustomAmount(!!isCustom);
                setIsEditingAmount(false);
              }}
              onContinue={() => handleNextStep(Screens.PaymentDetails)}
              onOtherAmount={() => {
                setCustomAmount(true);
                setIsEditingAmount(true);
              }}
              isCustomAmount={isCustomAmount}
              isEditingAmount={isEditingAmount}
            />
          </>
        ) : null}

        {step === Screens.PaymentDetails ? (
          <PaymentDetails
            value={amount}
            isMonthly={!!isMonthly}
            onBack={() => handleNextStep(Screens.AmountSelection)}
            onSuccess={() => handleNextStep(Screens.Success)}
            setIsMonthly={setActiveIndex}
            setFirstName={setFirstName}
          />
        ) : null}

        {step === Screens.Success ? (
          <PaymentSuccess
            amount={`${currencyLabel}${amount}`}
            setScreen={handleNextStep}
            name={firstName}
          />
        ) : null}

        {step === Screens.Intro ? (
          <GivingIntro
            setScreen={handleNextStep}
            onBackPress={() => handleNextStep(Screens.AmountSelection)}
          />
        ) : null}

        {step === Screens.History ? (
          <GivingHistory setScreen={handleNextStep} onBackPress={backToIntro} />
        ) : null}

        {step === Screens.Manage ? (
          <GivingManage
            setScreen={handleNextStep}
            onBackPress={backToIntro}
            amount={amount}
          />
        ) : null}

        {step === Screens.TaxReceipts ? (
          <TaxReceipts setScreen={handleNextStep} onBackPress={backToIntro} />
        ) : null}

        {step === Screens.PaymentMethod ? (
          <PaymentMethod setScreen={handleNextStep} onBackPress={backToIntro} />
        ) : null}

        {step === Screens.PaymentMethodAdd ? (
          <PaymentAdd setScreen={handleNextStep} onBackPress={backToIntro} />
        ) : null}
      </>
      <Alerts />
    </Container>
  );
});

export const PayItForwardScreen = () => {
  const user = useAppSelector(getUser);

  const isPreAuth = !user;

  return (
    <Screen screenName="giving">
      <HeaderBar isPreAuth={isPreAuth} />
      <PayItForward isPreAuth={isPreAuth} />
    </Screen>
  );
};
