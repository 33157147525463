import React from "react";
import * as Notifications from "expo-notifications";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { saveToken } from "~/state/push-notifications";
import { getIsLoggedIn } from "~/state/user/selectors";
import { UsePostAuthNavigation } from "~/navigation/post-auth";
import { MessageType } from "~/state/chat/types";
import { isWeb } from "~/utils/platform";

import { registerForPushNotificationsAsync } from "./register";
import { handleError } from "../logger";
import { navigateTo } from "../navigation";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const NotificationHandler = React.memo<Props>(({ children }) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<UsePostAuthNavigation>();
  const isLoggedIn = useAppSelector(getIsLoggedIn);

  const handlePushNotification = React.useCallback(
    (response: Notifications.NotificationResponse) => {
      const {
        notification: {
          request: {
            content: { data },
          },
        },
      } = response;

      const groupId = data?.groupId as string;
      const type = data?.type as MessageType;
      if (type === MessageType.GroupRemoved) {
        navigateTo(navigation, routes.groupsTab, {});
        return;
      }
      if (groupId) {
        navigation.navigate(routes.groupChat, { groupId });
        return;
      }
    },
    [navigation]
  );

  React.useEffect(() => {
    // Registering the push notifications breaks in dev mode on web
    if (__DEV__ && isWeb) return;

    // Don't register the device until the user is logged in
    if (!isLoggedIn) {
      return;
    }

    registerForPushNotificationsAsync()
      .then((token) => {
        if (token) {
          dispatch(saveToken({ token }));
        }
      })
      .catch((e) => {
        handleError(e);
      });

    // Handle push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener(
      handlePushNotification
    );
    return () => subscription.remove();
  }, [dispatch, handlePushNotification, isLoggedIn]);

  return <>{children}</>;
});
