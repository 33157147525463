import React from "react";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { useAlerts } from "~/state/alerts";
import { getUserId } from "~/state/user/selectors";
import { getPaymentIntentsAction } from "~/state/payments/actions";

import { Container, Title } from "../../styles";
import { AccountHeader } from "../../../header";
import { messages as generalMessages } from "../../intl";
import { Screens } from "../../../../types";

import { HistoryContent } from "./content";
import { genericMessages } from "~/constants/intl";
import { colors } from "~/styles/theme";

interface Props {
  onBackPress: () => void;
  setScreen: (screen: Screens) => void;
}

export const GivingHistory = React.memo<Props>(({ onBackPress }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const userId = useAppSelector(getUserId);

  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  React.useEffect(() => {
    if (!userId) {
      return;
    }

    dispatch(
      getPaymentIntentsAction({
        onSuccess: () => setIsLoading(false),
        onError: () => {
          setIsLoading(false);
          pushAlert({
            message: genericMessages.error,
            color: colors.red500,
          });
        },
      })
    );
  }, [dispatch, userId]);

  return (
    <Container>
      <AccountHeader onBackPress={onBackPress} />

      <Title>{generalMessages.history}</Title>

      <HistoryContent isLoading={isLoading} />
    </Container>
  );
});
