import uuid from "react-native-uuid";
import * as Localization from "expo-localization";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  doc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
  DocumentSnapshot,
} from "firebase/firestore";
import moment from "moment-timezone";
import NetInfo from "@react-native-community/netinfo";

import { database } from "<config>/firebase";
import { getEnvironment, getUserId } from "~/state/user/selectors";

import {
  updateReminders,
  addReminder,
  deleteReminder,
  editReminder,
} from "./slice";
import {
  getReminders,
  addReminder as addReminderAction,
  editReminder as editReminderAction,
} from "./actions";
import {
  GetRemindersAction,
  AddReminderAction,
  DeleteReminderAction,
  EditReminderAction,
  Reminder,
  ReminderTypes,
} from "./types";
import { getRemindersCollection } from "~/constants/collections";
import { handleError } from "~/utils/logger";

const getUtcHour = (hour: number, timezone: string) => {
  const localTime = moment()
    .tz(timezone)
    .set({ hour, minute: 0, second: 0, millisecond: 0 });

  return localTime.utc().hour();
};

export function* getRemindersFn({
  payload: { onSuccess, onError },
}: GetRemindersAction): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    yield call(onSuccess);

    return;
  }

  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }
  try {
    const env = yield select(getEnvironment);
    const remindersCollection = getRemindersCollection(env);

    const q = query(
      collection(database, remindersCollection),
      where("userId", "==", userId)
    );

    const notesSnapshots = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const data = notesSnapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as Reminder[];

    if (data && Array.isArray(data)) {
      yield put(updateReminders(data));
      yield call(onSuccess);
    } else {
      throw new Error();
    }
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* addReminderFn({
  payload: { daysOfWeek, hour, minute, onSuccess, onError },
}: AddReminderAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const remindersCollection = getRemindersCollection(env);

    const userId = yield select(getUserId);
    const id = uuid.v4() as string;
    const lastUpdated = new Date().getTime();
    const calendars = yield call(Localization.getCalendars);
    const timezone = calendars[0]?.timezone || moment.tz.guess();
    const utcHour = getUtcHour(hour, timezone);

    const reminder = {
      id,
      daysOfWeek,
      hour,
      utcHour,
      minute,
      timezone,
      lastUpdated,
      userId,
      isActive: true,
      type: ReminderTypes.Learning,
    };

    yield call(
      // @ts-ignore
      setDoc,
      doc(database, remindersCollection, id),
      reminder
    );

    // Update the local state to avoid an extra Firebase query
    yield put(addReminder(reminder));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* editReminderFn({
  payload: { id, daysOfWeek, hour, minute, onSuccess, onError },
}: EditReminderAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const remindersCollection = getRemindersCollection(env);

    yield call(
      // @ts-ignore
      updateDoc,
      doc(database, remindersCollection, id),
      { daysOfWeek, hour, minute, isActive: true }
    );

    // Update the local state to avoid an extra Firebase query
    yield put(editReminder({ id, daysOfWeek, hour, minute }));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* deleteReminderFn({
  payload: { id, onSuccess, onError },
}: DeleteReminderAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const remindersCollection = getRemindersCollection(env);

    yield call(
      // @ts-ignore
      deleteDoc,
      doc(database, remindersCollection, id)
    );

    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const addRemindersSaga = function* () {
  yield takeLatest(addReminderAction.type, addReminderFn);
};

export const getRemindersSaga = function* () {
  yield takeLatest(getReminders.type, getRemindersFn);
};

export const editReminderSaga = function* () {
  yield takeLatest(editReminderAction.type, editReminderFn);
};

export const deleteReminderSaga = function* () {
  yield takeLatest(deleteReminder.type, deleteReminderFn);
};
