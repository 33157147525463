import { createAction } from "@reduxjs/toolkit";

import {
  GetRemindersPayload,
  AddReminderPayload,
  EditReminderPayload,
} from "./types";

export const getReminders = createAction<GetRemindersPayload>("getReminders");

export const addReminder = createAction<AddReminderPayload>("addReminder");

export const editReminder = createAction<EditReminderPayload>("editReminder");
