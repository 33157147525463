import React from "react";

import { SettingsScreen } from "~/components/settings";
import { useAppDispatch } from "~/state/hooks";
import { getInAppNotifications as getInAppNotificationsAction } from "~/state/in-app-notifications-setup/actions";

import { AnnouncementList } from "./components/list";
import { AnnouncementForm } from "./components/form";
import { Container } from "./styles";
import { Loader } from "~/components/loader";

export const InAppMessages = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isCreateMode, setIsCreateMode] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState("");

  React.useEffect(() => {
    //setIsLoading(true);
    dispatch(
      getInAppNotificationsAction({
        onSuccess: () => setIsLoading(false),
        onError: () => setIsLoading(false),
      })
    );
  }, []);

  if (isLoading) {
    return <Loader fullScreen />;
  }

  return (
    <SettingsScreen screenName="in-app-messages">
      <Container>
        {activeItem ? (
          <AnnouncementForm id={activeItem} onBack={() => setActiveItem("")} />
        ) : null}
        {isCreateMode ? (
          <AnnouncementForm onBack={() => setIsCreateMode(false)} />
        ) : null}
        {!activeItem && !isCreateMode ? (
          <AnnouncementList
            onCreate={() => setIsCreateMode(true)}
            onSelectItem={setActiveItem}
          />
        ) : null}
      </Container>
    </SettingsScreen>
  );
};
