import { Share } from "react-native";
import Toast from "react-native-root-toast";
import { SagaIterator } from "redux-saga";
import {
  call,
  takeLatest,
  put,
  select,
  all,
  takeEvery,
} from "redux-saga/effects";
import NetInfo from "@react-native-community/netinfo";
import * as Localization from "expo-localization";
import * as FileSystem from "~/utils/file-system";
import { unzip } from "~/utils/zip-archive";

import {
  getCleanSessionFiles,
  getDevotionsBySessionId,
  getFlamelinkFilesByIds,
  getFlamelinkFilesMetaByIds,
  getFlamelinkFilesToProcess,
  getLessonData,
  getLessonsBySessionId,
  getPersonalDevotionData,
  getPlanFileIds,
  getSessionData,
  getSessionsBySessionId,
  getSessionsByVolumeId,
  getVolumeAdditionalFileIds,
  getVolumeFileIds,
  getVolumeSessions,
} from "~/state/flamelink/selectors";
import { appStartedLoading } from "~/state/startup/slice";
import { chunkArray } from "~/utils/arrays";
import { getEnvironment } from "~/state/user/selectors";
import { preLogout, setIsUserReady } from "~/state/user/actions";
import { handleError } from "~/utils/logger";
import { isAndroid, isWeb } from "~/utils/platform";
import { formatMessage } from "~/utils/translation";
import { colors } from "~/styles/theme";
import { setLibraryFilters, setLibraryFiltersOptions } from "~/state/ui/slice";
import {
  getFiltersFetchTime,
  getLibraryFiltersOptions,
} from "~/state/ui/selectors";
import { resetFilters } from "~/state/ui/actions";

import { getLanguagesToDisplay, setLanguagesToDisplay } from "../settings";
import {
  removeAllDownloadedSessions,
  removeAllDownloads,
  removeDownload,
  removeDownloadedSession,
  setDownloadedSession,
  setDownloadedSessions,
  setDownloads,
  setDownloadsBulk,
  setFeaturedContent,
  setFile,
  setFiles,
  setFlamelinkData,
  setFlamelinkImages,
  setLesson,
  setPersonalDevotion,
  setSession,
} from "./slice";
import {
  getDownloadedFiles,
  getFlamelinkFiles,
  getFlamelinkFilesById,
  getFlamelinkFilesMetaById,
  getPlans,
  getSessionFileIds,
  getFeaturedFetchTime,
} from "./selectors";
import {
  deleteDownloadFromFileSystem,
  executeDownloadFile,
  executeWebDownload,
  fetchFiltersOptions,
  fetchFlamelinkData,
  fetchLesson,
  fetchPersonalDevotion,
  fetchSession,
  getFlamelinkMedia,
  openPresentationOrPDF,
} from "./side-effects";
import {
  DownloadFileAction,
  FlamelinkMeta,
  FlamelinkLocales,
  FlamelinkMediaFile,
  PlansContent,
  OpenFileAction,
  DownloadSessionAction,
  FlamelinkFile,
  DeleteDownloadAction,
  FlamelinkFileMeta,
  LoadFlamelinkFileAction,
  LoadFlamelinkAllFilesAction,
  LoadSessionAction,
  LoadPersonalDevotionAction,
  LoadLessonAction,
  LoadLessonsBySessionIdAction,
  FeaturedContent,
  LoadFlamelinkFilesAction,
  LoadVolumeFilesAction,
  LoadPlanFilesAction,
  DownloadVolumeAction,
  ProcessZipFileAction,
} from "./types";
import {
  FEATURED_CACHE_TIME_MS,
  FILTERS_CACHE_TIME_MS,
  FLAMELINK_DATA_KEY,
  FLAMELINK_IMAGES_KEY,
  SUPPORTED_LOCALES,
} from "./constants";
import {
  deleteAllDownloads,
  deleteDownload,
  downloadFile,
  downloadSession,
  downloadVolume,
  loadAllFlamelinkFiles,
  loadFlamelinkFile,
  loadFlamelinkFiles,
  loadLesson,
  loadLessonsBySessionId,
  loadPersonalDevotion,
  loadPlanFiles,
  loadSession,
  loadVolumeFiles,
  openFile,
  processZipFile,
} from "./actions";
import {
  extractInitialFilters,
  getLocalFlamelinkData,
  saveLocalFlamelinkData,
} from "./utils";
import { messages } from "./intl";
import { getCmsContentCollection } from "~/constants/collections";
import { doc, getDoc } from "firebase/firestore";
import { database } from "<config>/firebase";
import { DownloadProgressData } from "~/utils/file-system";

function* handleGetFlamelinkData(): SagaIterator {
  try {
    const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> =
      yield call(NetInfo.fetch);

    if (!isConnected) {
      // TODO: in the future this can be an useful logic to improve the app performance, avoiding
      // unnecessary calls if we already have the necessary data (applying some hours of cache)
      const localData = yield call(getLocalFlamelinkData, FLAMELINK_DATA_KEY);
      if (isAndroid && localData?.sessions) {
        yield put(setFlamelinkData(localData));
        return;
      }
      return;
    }

    const env = yield select(getEnvironment);
    const results: Awaited<Promise<FlamelinkMeta>> = yield call(
      fetchFlamelinkData,
      env
    );

    if (
      // @ts-ignore
      results?.error?.code === "permission-denied"
    ) {
      yield put(preLogout());
      return;
    }

    // @ts-ignore
    if (!results || results?.error) {
      return;
    }

    yield put(setFlamelinkData(results));
    yield call(saveLocalFlamelinkData, FLAMELINK_DATA_KEY, results);
    yield call(handleGetFlamelinkImages);
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleLoadFlamelinkFile({
  payload: { fileId },
}: LoadFlamelinkFileAction): SagaIterator {
  try {
    const fileData = yield select(getFlamelinkFilesById, fileId);

    // we can skip this if we already have the file processed
    if (fileData) return;

    const file: FlamelinkFileMeta = yield select(
      getFlamelinkFilesMetaById,
      fileId
    );

    if (!file) return;

    const previewId = file.preview?.[0] ?? null;
    const fileSD = file.file?.[0] ?? null;
    const fileHDId = file.fileHD?.[0] ?? null;
    const fileUltraHDId = file.fileUltraHD?.[0] ?? null;

    const allIds = [previewId, fileSD, fileHDId, fileUltraHDId].filter(
      Boolean
    ) as string[];

    if (!allIds?.length) {
      return;
    }

    const filesMetadata: FlamelinkMediaFile[] = yield call(getFlamelinkMedia, [
      allIds,
    ]);

    const previewFile = filesMetadata.find(
      (metadata) => metadata.id === file.preview[0]
    );
    const sdFile = filesMetadata.find(
      (metadata) => metadata.id === file.file?.[0]
    );
    const hdFile = filesMetadata.find(
      (metadata) => metadata.id === file.fileHD?.[0]
    );
    const ultraHDFile = filesMetadata.find(
      (metadata) => metadata.id === file.fileUltraHD?.[0]
    );

    const formattedFile: FlamelinkFile = {
      ...file,
      preview: previewFile,
      file: sdFile,
      fileHD: hdFile,
      fileUltraHD: ultraHDFile,
    };

    yield put(setFile(formattedFile));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleLoadAllFlamelinkFiles({
  payload: { fileIds },
}: LoadFlamelinkAllFilesAction): SagaIterator {
  try {
    const filesToProcess = yield select(getFlamelinkFilesToProcess, fileIds);

    // We can skip this if we already have the files processed
    if (!filesToProcess.length) return;

    const files: FlamelinkFileMeta[] = yield select(
      getFlamelinkFilesMetaByIds,
      filesToProcess
    );

    if (!files.length) return;

    const allIds = files
      .map((file) => {
        const previewId = file.preview?.[0] ?? null;
        const fileSD = file.file?.[0] ?? null;
        const fileHDId = file.fileHD?.[0] ?? null;
        const fileUltraHDId = file.fileUltraHD?.[0] ?? null;

        return [previewId, fileSD, fileHDId, fileUltraHDId].filter(
          Boolean
        ) as string[];
      })
      .flat();

    if (!allIds?.length) {
      return;
    }

    const filesMetadata: FlamelinkMediaFile[] = yield call(
      getFlamelinkMedia,
      chunkArray(allIds, 10)
    );

    const formattedFiles: FlamelinkFile[] = files.map((file) => {
      const previewFile = filesMetadata.find(
        (metadata) => metadata.id === file.preview[0]
      );
      const sdFile = filesMetadata.find(
        (metadata) => metadata.id === file.file?.[0]
      );
      const hdFile = filesMetadata.find(
        (metadata) => metadata.id === file.fileHD?.[0]
      );
      const ultraHDFile = filesMetadata.find(
        (metadata) => metadata.id === file.fileUltraHD?.[0]
      );

      return {
        ...file,
        preview: previewFile,
        file: sdFile,
        fileHD: hdFile,
        fileUltraHD: ultraHDFile,
      };
    });

    yield put(setFiles(formattedFiles));
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* handleGetFlamelinkImages(): SagaIterator {
  try {
    const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> =
      yield call(NetInfo.fetch);

    if (!isConnected) {
      const localData = yield call(getLocalFlamelinkData, FLAMELINK_IMAGES_KEY);
      if (isAndroid && Array.isArray(localData)) {
        yield put(setFlamelinkImages(localData));
        return;
      }
      return;
    }

    const plans: PlansContent[] = yield select(getPlans);
    const plansCovers = plans?.map((plan) => plan.cover[0]).filter(Boolean);
    const plansLogos = plans?.map((plan) => plan.logo[0]).filter(Boolean);

    // splitting into chunks of 10 because of the firestore query limit
    const chunkedCoversIds = chunkArray(plansCovers, 10);
    const chunkedLogosIds = chunkArray(plansLogos, 10);

    const coverFiles: FlamelinkMediaFile[] = yield call(
      getFlamelinkMedia,
      chunkedCoversIds
    );
    const logoFiles: FlamelinkMediaFile[] = yield call(
      getFlamelinkMedia,
      chunkedLogosIds
    );

    const flamelinkImages = plans.map((plan) => {
      const cover = coverFiles?.find(
        (image) => image.id === plan.cover[0]
      )?.file;
      const logo = logoFiles?.find((image) => image.id === plan.logo[0])?.file;

      return { planId: plan.id, cover, logo };
    });

    yield put(setFlamelinkImages(flamelinkImages));
    yield call(saveLocalFlamelinkData, FLAMELINK_IMAGES_KEY, flamelinkImages);
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleSetResourcesLanguages(reset = false): SagaIterator {
  try {
    const deviceLocale = Localization.locale.split("-")[0];
    const languagesToDisplay = yield select(getLanguagesToDisplay);

    const isDeviceLocaleSupported = SUPPORTED_LOCALES.includes(deviceLocale);

    if (!languagesToDisplay?.length || reset === true) {
      yield put(
        setLanguagesToDisplay([
          isDeviceLocaleSupported
            ? FlamelinkLocales[deviceLocale as keyof typeof FlamelinkLocales]
            : FlamelinkLocales.en,
        ])
      );
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleDownloadFile({
  payload: { fileId, fileName, sessionId, onUpdate, onError },
}: DownloadFileAction): SagaIterator {
  try {
    if (isWeb) {
      yield call(executeWebDownload, fileName, onUpdate);

      return;
    }

    const download: Awaited<ReturnType<typeof executeDownloadFile>> =
      yield call(executeDownloadFile, { fileName, onUpdate });

    const fileSize: Awaited<ReturnType<typeof FileSystem.getInfoAsync>> =
      yield call(FileSystem.getInfoAsync, download?.uri ?? "");

    if (download?.uri) {
      yield put(
        setDownloads({
          id: fileId,
          resourceLocation: download.uri,
          size: fileSize.size ?? 0,
          sessionId,
        })
      );
    }
  } catch (error) {
    yield call(onError, error);
    yield call(handleError, error);
  }
}

function* handleDeleteDownload({
  payload: { fileId, filePath, sessionId },
}: DeleteDownloadAction): SagaIterator {
  try {
    yield call(deleteDownloadFromFileSystem, filePath);

    yield put(removeDownload(fileId));

    if (sessionId) {
      yield put(removeDownloadedSession(sessionId));
    }
  } catch (error) {
    yield call(handleError, error);
  }
}

function* handleDeleteAllDownloads(): SagaIterator {
  try {
    const downloads: ReturnType<typeof getDownloadedFiles> = yield select(
      getDownloadedFiles
    );

    yield all(
      downloads.map((download) =>
        call(deleteDownloadFromFileSystem, download?.resourceLocation)
      )
    );

    yield put(removeAllDownloads());
    yield put(removeAllDownloadedSessions());
  } catch (error) {
    yield call(handleError, error);
  }
}

function* handleOpenFile({
  payload: { downloadedResourceLocation, fileName },
}: OpenFileAction): SagaIterator {
  if (!downloadedResourceLocation) {
    try {
      yield call(openPresentationOrPDF, {
        downloadedResourceLocation,
        fileName,
      });
    } catch (error) {
      yield call(handleError, error);
    }

    return;
  }

  Share.share({
    url: downloadedResourceLocation,
  });

  return;
}

function* handleFetchFilters(): SagaIterator {
  try {
    const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> =
      yield call(NetInfo.fetch);

    if (!isConnected) return;

    const env = yield select(getEnvironment);
    const filtersFetchTime = yield select(getFiltersFetchTime);

    const now = new Date().getTime();
    const shouldFetchFilters = now > filtersFetchTime + FILTERS_CACHE_TIME_MS;

    if (shouldFetchFilters) {
      const filters: Awaited<ReturnType<typeof fetchFiltersOptions>> =
        yield call(fetchFiltersOptions, env);

      if (filters) {
        yield put(setLibraryFiltersOptions(filters));

        const initialFilters = yield call(extractInitialFilters, filters);

        yield put(setLibraryFilters(initialFilters));
      }
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleResetFilters(): SagaIterator {
  const filterOptions = yield select(getLibraryFiltersOptions);
  const resettedFilters = yield call(extractInitialFilters, filterOptions);

  yield put(setLibraryFilters(resettedFilters));
  yield call(handleSetResourcesLanguages, true);
}

function* handleLoadSession({
  payload: sessionId,
}: LoadSessionAction): SagaIterator {
  try {
    const storedSession = yield select(getSessionData, sessionId);

    if (storedSession) return;

    const env: ReturnType<typeof getEnvironment> = yield select(getEnvironment);

    const session: Awaited<ReturnType<typeof fetchSession>> = yield call(
      fetchSession,
      env,
      sessionId
    );

    yield put(setSession(session));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleLoadPersonalDevotion({
  payload: personalDevotionId,
}: LoadPersonalDevotionAction): SagaIterator {
  try {
    const storedPersonalDevotion = yield select(
      getPersonalDevotionData,
      personalDevotionId
    );

    if (storedPersonalDevotion) return;

    const env: ReturnType<typeof getEnvironment> = yield select(getEnvironment);

    const personalDevotion: Awaited<ReturnType<typeof fetchPersonalDevotion>> =
      yield call(fetchPersonalDevotion, env, personalDevotionId);

    yield put(setPersonalDevotion(personalDevotion));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleLoadLesson({
  payload: lessonId,
}: LoadLessonAction): SagaIterator {
  try {
    const storedLesson: ReturnType<typeof getLessonData> = yield select(
      getLessonData,
      lessonId
    );

    if (storedLesson) return;

    const env: ReturnType<typeof getEnvironment> = yield select(getEnvironment);

    const lesson: Awaited<ReturnType<typeof fetchLesson>> = yield call(
      fetchLesson,
      env,
      lessonId
    );

    yield put(setLesson(lesson));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleLoadLessonsBySessionId({
  payload: sessionId,
}: LoadLessonsBySessionIdAction): SagaIterator {
  try {
    const lessons: ReturnType<typeof getLessonsBySessionId> = yield select(
      getLessonsBySessionId,
      sessionId
    );

    if (!lessons || !lessons.length) return;

    const lessonsCalls = lessons.map((lesson) =>
      call(handleLoadLesson, { type: "loadLesson", payload: lesson.id })
    );

    yield all(lessonsCalls);
  } catch (e) {
    yield call(handleError, e);
  }
}

function* getSessionFilesToDownload(sessionId: string) {
  const sessionFileIds: string[] = yield select(getSessionFileIds, sessionId);

  const loadFlamelinkFilesMetadataCalls = sessionFileIds.map((fileId) =>
    call(handleLoadFlamelinkFile, {
      type: loadFlamelinkFile.type,
      payload: { fileId },
    })
  );

  // Preloads the metadata for all the files in the session, so that they are available in the next step (getFlamelinkFiles)
  yield all([...loadFlamelinkFilesMetadataCalls]);

  const filesMetadata: FlamelinkFile[] = yield select(getFlamelinkFiles);

  return filesMetadata
    .filter((file) => !file.hidden)
    .filter((metadata) =>
      sessionFileIds.some((fileId) => fileId === metadata.id)
    )
    .map(({ file }) => ({ id: file?.id ?? "", file: file?.file ?? "" }));
}

function* handleDownloadSession({
  payload: { sessionId, onUpdate },
}: DownloadSessionAction): SagaIterator {
  try {
    // initialising a "fake" progress just to indicate that the download has started
    onUpdate("", {
      totalBytesWritten: 10,
      totalBytesExpectedToWrite: 100,
    });

    yield call(handleLoadSession, { type: "loadSession", payload: sessionId });

    const days: ReturnType<typeof getDevotionsBySessionId> = yield select(
      getDevotionsBySessionId,
      sessionId
    );
    const lessons: ReturnType<typeof getLessonsBySessionId> = yield select(
      getLessonsBySessionId,
      sessionId
    );
    const childLessons: ReturnType<typeof getSessionsBySessionId> =
      yield select(getSessionsBySessionId, sessionId);

    // Trigger the queries for all the days, lessons and child sessions in the session
    const personalDevotionsCalls = days.map((day) =>
      call(handleLoadPersonalDevotion, {
        type: "loadPersonalDevotion",
        payload: day.id,
      })
    );
    const lessonsCalls = lessons.map((lesson) =>
      call(handleLoadLesson, {
        type: "loadLesson",
        payload: lesson.id,
      })
    );
    const childSessionsCalls = childLessons.map((childLesson) =>
      call(handleLoadSession, {
        type: "loadSession",
        payload: childLesson.id,
      })
    );

    const filesToDownload = yield call(getSessionFilesToDownload, sessionId);

    const filesToDownloadCalls = filesToDownload.map((file) =>
      call(handleDownloadFile, {
        type: downloadFile.type,
        payload: {
          fileId: file.id,
          fileName: file.file,
          sessionId,
          onUpdate: (progress) => {
            const roundedProgress = Math.round(
              (progress.totalBytesWritten /
                progress.totalBytesExpectedToWrite) *
                100
            );

            // avoid calling onUpdate every time the progress changes, only call it in certain intervals
            switch (roundedProgress) {
              case 5:
              case 10:
              case 20:
              case 35:
              case 50:
              case 75:
              case 100:
                onUpdate(file.id, progress);
                break;
            }
          },
          onError: () => {
            Toast.show(formatMessage(messages.error), {
              duration: Toast.durations.LONG,
              position: Toast.positions.BOTTOM,
              shadow: true,
              animation: true,
              hideOnPress: true,
              backgroundColor: colors.red500,
              delay: 0,
            });
          },
        },
      })
    );

    // Use the `all` effect to run all the calls concurrently
    yield all([
      ...filesToDownloadCalls,
      ...personalDevotionsCalls,
      ...lessonsCalls,
      ...childSessionsCalls,
    ]);

    // finalising the progress - this is useful in case there are no files to download
    onUpdate("", {
      totalBytesWritten: 100,
      totalBytesExpectedToWrite: 100,
    });

    yield put(setDownloadedSession(sessionId));
  } catch (e) {
    yield call(handleError, e);
  }
}

const clampValue = (value: number) => Math.min(Math.max(value, 0), 99);

const getProgress = (input: number, output: number, percentage: number) =>
  formatMessage(messages.filesDownload, {
    input,
    output,
    percentage,
  });

function* handleDownloadVolume({
  payload: { volumeId, onUpdate },
}: DownloadVolumeAction): SagaIterator {
  try {
    let downloadableFiles = 0;
    let localProcessed = 0;
    const processed = new Set();

    const handleUpdate = (id: string, progress: DownloadProgressData) => {
      const isProcessed =
        Math.round(
          progress.totalBytesWritten / progress.totalBytesExpectedToWrite
        ) === 1;
      const processedFiles = Math.max(0, processed.size - 2);
      const totalProcessed = clampValue(
        Math.round((processedFiles / downloadableFiles) * 100)
      );

      if (isProcessed) {
        processed.add(id);
        if (totalProcessed > localProcessed) {
          onUpdate(
            getProgress(processedFiles, downloadableFiles, totalProcessed)
          );
          localProcessed = totalProcessed;
        }
      } else {
        const maxValue = Math.round((processedFiles / downloadableFiles) * 100);
        if (localProcessed > maxValue) {
          return;
        }
        localProcessed = localProcessed + 1;
        onUpdate(
          getProgress(
            processedFiles,
            downloadableFiles,
            clampValue(localProcessed)
          )
        );
      }
    };
    const volumeSessions = yield select(getSessionsByVolumeId, volumeId);
    for (let i = 0; i < volumeSessions.length; i++) {
      const filesToDownload = yield call(
        getSessionFilesToDownload,
        volumeSessions[i]
      );
      downloadableFiles = downloadableFiles + filesToDownload.length;
      yield put(
        downloadSession({
          sessionId: volumeSessions[i],
          onUpdate: handleUpdate,
        })
      );
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

/**
 * We want to prevent resetting the cache if there is no internet connection
 * because the cache will be empty and the user will not be able to see any data.
 * However, if there is internet connection, we want to reset the cache
 * to ensure that the data is up to date.
 */
function* handleFlamelinkApiRehydration() {
  try {
    const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> =
      yield call(NetInfo.fetch);

    if (isConnected) {
      // @TODO: check how to handle this moving forward as we won't use api anymore
      // yield put(flamelinkApi.util.resetApiState());
    }
  } catch (error) {
    yield call(handleError, error);
  }
}

function* handleGetFeaturedContent(): SagaIterator {
  try {
    const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> =
      yield call(NetInfo.fetch);

    if (!isConnected) return;

    const env = yield select(getEnvironment);
    const featuredFetchTime = yield select(getFeaturedFetchTime);

    const now = new Date().getTime();
    const shouldFetchData = now > featuredFetchTime + FEATURED_CACHE_TIME_MS;

    if (shouldFetchData) {
      const cmsContentCollection = getCmsContentCollection(env);

      const docRef = doc(database, cmsContentCollection, "featured");

      // @ts-ignore
      const querySnapshot = yield call(getDoc, docRef);

      const data = querySnapshot.data()?.data as FeaturedContent[];

      if (data) {
        yield put(setFeaturedContent(data));

        const allIds = [
          ...new Set(
            data.map(({ file }) => (Array.isArray(file) ? file : [])).flat()
          ),
        ];

        const filesMetadata: FlamelinkMediaFile[] = yield call(
          getFlamelinkMedia,
          [allIds]
        );

        yield put(
          // @ts-ignore
          setFiles(filesMetadata)
        );
      }
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleLoadFlamelinkFiles({
  payload: { fileIds, onSuccess, onError },
}: LoadFlamelinkFilesAction): SagaIterator {
  try {
    if (!fileIds.length) {
      return;
    }

    const effects = fileIds.map((fileId) =>
      call(handleLoadFlamelinkFile, {
        payload: { fileId },
      } as LoadFlamelinkFileAction)
    );

    yield all(effects);

    for (const fileId of fileIds) {
      yield call(handleLoadFlamelinkFile, {
        payload: { fileId },
      } as LoadFlamelinkFileAction);
    }

    const fileData = yield select(getFlamelinkFilesByIds, fileIds);
    yield call(onSuccess, fileData);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

function* handleLoadVolumeFiles({
  payload: { volumeId },
}: LoadVolumeFilesAction): SagaIterator {
  try {
    if (!volumeId) {
      return;
    }

    const volumeFileIds = yield select(getVolumeFileIds, volumeId);

    yield put(loadAllFlamelinkFiles({ fileIds: volumeFileIds }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleLoadPlanFiles({
  payload: { planId },
}: LoadPlanFilesAction): SagaIterator {
  try {
    if (!planId) {
      return;
    }

    const fileIds = yield select(getPlanFileIds, planId);

    yield put(loadAllFlamelinkFiles({ fileIds }));
  } catch (e) {
    yield call(handleError, e);
  }
}

function* handleProcessZipFile({
  payload: { uri, volumeId, onSuccess, onError },
}: ProcessZipFileAction): SagaIterator {
  try {
    if (!volumeId || !uri) {
      return;
    }

    const targetDirectory =
      FileSystem.documentDirectory + "bep/files/downloads/";

    const directoryInfo = yield call(FileSystem.getInfoAsync, targetDirectory);

    if (!directoryInfo.exists) {
      yield call(FileSystem.makeDirectoryAsync, targetDirectory, {
        intermediates: true,
      });
    }

    const unzippedUri = yield call(unzip, uri, targetDirectory);
    const volumeFileIds = yield select(getVolumeFileIds, volumeId);
    const fileData = yield select(getFlamelinkFilesByIds, volumeFileIds);

    const filesBySession = new Map();
    const sessions = yield select(getSessionsByVolumeId, volumeId);
    if (!Array.isArray(sessions)) {
      return;
    }
    for (const sessionId of sessions) {
      const sessionFiles = yield select(getCleanSessionFiles, sessionId);

      if (!Array.isArray(sessionFiles)) {
        continue;
      }
      for (const sessionFile of sessionFiles) {
        filesBySession.set(sessionFile?.id, sessionId);
      }
    }
    yield put(setDownloadedSessions(sessions));

    const downloadedFiles = new Map();

    for (const item of fileData) {
      const resourceLocation = `${unzippedUri}${item.file.file}`;
      const { exists, size = 0 } = yield call(
        FileSystem.getInfoAsync,
        resourceLocation
      );
      if (exists) {
        const fileId = item?.file?.id;
        const resourceId = item?.id;

        downloadedFiles.set(fileId, {
          id: fileId,
          resourceLocation,
          size,
          sessionId: filesBySession.get(resourceId),
        });
      }
    }

    yield put(setDownloadsBulk(downloadedFiles));
    yield call(FileSystem.deleteAsync, uri);

    yield call(onSuccess);
  } catch (e) {
    yield call(handleError, e);
    yield call(onError);
  }
}

export function* flamelinkSaga() {
  yield takeLatest(appStartedLoading.type, handleSetResourcesLanguages);
  yield takeLatest(appStartedLoading.type, handleFlamelinkApiRehydration);
  yield takeEvery(downloadFile.type, handleDownloadFile);
  yield takeEvery(deleteDownload.type, handleDeleteDownload);
  yield takeLatest(deleteAllDownloads.type, handleDeleteAllDownloads);
  yield takeLatest(openFile.type, handleOpenFile);
  yield takeLatest(resetFilters.type, handleResetFilters);
  yield takeEvery(downloadSession.type, handleDownloadSession);
  yield takeLatest(downloadVolume.type, handleDownloadVolume);
  yield takeEvery(loadFlamelinkFile.type, handleLoadFlamelinkFile);
  yield takeEvery(loadFlamelinkFiles.type, handleLoadFlamelinkFiles);
  yield takeEvery(loadAllFlamelinkFiles.type, handleLoadAllFlamelinkFiles);
  yield takeEvery(loadVolumeFiles.type, handleLoadVolumeFiles);
  yield takeEvery(loadPlanFiles.type, handleLoadPlanFiles);
  yield takeEvery(loadSession.type, handleLoadSession);
  yield takeEvery(loadPersonalDevotion.type, handleLoadPersonalDevotion);
  yield takeEvery(loadLesson.type, handleLoadLesson);
  yield takeEvery(loadLessonsBySessionId.type, handleLoadLessonsBySessionId);
  // sagas that need be executed a first time post authentication
  yield takeLatest(setIsUserReady.type, handleGetFlamelinkData);
  yield takeLatest(setIsUserReady.type, handleFetchFilters);
  yield takeLatest(setIsUserReady.type, handleGetFeaturedContent);
  yield takeLatest(processZipFile.type, handleProcessZipFile);
}
