import OneSignal from "react-onesignal";

export const init = OneSignal.init;

export const login = (externalId: string) => {
  if (typeof window.OneSignalDeferred?.push !== "function") {
    return;
  }
  window.OneSignalDeferred.push(async function (instance: typeof OneSignal) {
    await instance?.login(externalId);
  });
};

export const logout = () => {
  if (typeof window.OneSignalDeferred?.push !== "function") {
    return;
  }

  window.OneSignalDeferred.push(async function (instance: typeof OneSignal) {
    await instance.logout();
  });
};
