export const messages = {
  giving: {
    id: "giving.my-giving",
    defaultMessage: "My Giving",
  },
  history: {
    id: "giving.history",
    defaultMessage: "Giving History",
  },
  manage: {
    id: "giving.manage",
    defaultMessage: "Manage Giving",
  },
  taxReceipts: {
    id: "giving.tax-receipts",
    defaultMessage: "Tax Receipts",
  },
  faq: {
    id: "giving.faq",
    defaultMessage: "Giving FAQs",
  },
  contact: {
    id: "giving.contact",
    defaultMessage: "Contact Us",
  },
  download: {
    id: "giving.download",
    defaultMessage: "Download",
  },
  taxReceiptDescription: {
    id: "giving.tax-receipt-description",
    defaultMessage: "Download a comprehensive summary of your contributions",
  },
};
