import styled from "styled-components/native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { Text2 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

export const Container = styled.View`
  margin-top: ${spacers.ss6};
  margin-bottom: ${spacers.ss12};
`;

export const Box = styled.View`
  margin-horizontal: ${spacers.ss2};
  margin-bottom: ${spacers.ss2};
  flex: 1;
`;

export const HorizontalBox = styled.View`
  flex-direction: row;
`;

export const DeleteButton = styled.TouchableOpacity`
  align-self: center;
  margin-vertical: ${spacers.ss6};
  flex-direction: row;
  justify-items: center;
`;

export const DeleteIcon = styled(Icon).attrs({
  name: "delete-forever",
  color: colors.red500,
  size: 16,
})``;

export const DeleteText = styled(Text2)`
  color: ${colors.red500};
  margin-left: ${spacers.ss4};
`;
