import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { colors, spacers } from "~/styles/theme";
import { hexToRGB } from "~/utils/colors";
import { isAndroid, isWeb, windowWidth } from "~/utils/platform";
import { Text2 } from "~/components/text";

export const VideoContainer = styled.View`
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: ${spacers.ss5} 0;
`;

const mobileAspectRatio = windowWidth * (9 / 16);

export const VideoMask = styled.View`
  ${StyleSheet.absoluteFill};
  width: 100%;
  height: ${mobileAspectRatio}px;
  background-color: ${hexToRGB(colors.black, 0.6)};
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const ThumbnailContainer = styled.TouchableOpacity`
  ${StyleSheet.absoluteFill};
  width: 100%;
  height: ${mobileAspectRatio}px;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

// @TODO: The cache one works good if the content was already open
// but upon going offline, we can't handle the error to show the fallback mask
// Set up expo-image moving forward to handle these scenarios
// export const Thumbnail = styled(ImageCache)`
export const Thumbnail = styled.Image`
  ${StyleSheet.absoluteFill};
  width: 100%;
  height: ${mobileAspectRatio}px;
`;

export const videoStyles = StyleSheet.create({
  video: {
    width: "100%",
    ...(isWeb ? { maxHeight: 500 } : { height: mobileAspectRatio }),
  },
}).video;

export const DebugContainer = styled.View`
  width: 100%;
  background-color: ${colors.red700};
  padding: ${spacers.ss4};
`;

export const DebugText = styled(Text2)`
  color: ${colors.white};
  margin: ${spacers.ss4} 0;
`;

export const ErrorText = styled(Text2)`
  color: ${colors.white};
  margin: ${spacers.ss4} 0;
`;

export const GoogleCastBox = styled.View`
  position: absolute;
  top: ${isAndroid ? 16 : 62}px;
  left: ${isAndroid ? 15 : 10}px;
`;
