import styled from "styled-components/native";
import { Snackbar } from "react-native-paper";

import { colors, spacers } from "~/styles/theme";
import { Text1, Text2 } from "~/components/text";
import { isWeb } from "~/utils/platform";

export const Alert = styled(Snackbar)<{ color?: string }>`
  background-color: ${({ color }) => color || colors.red600};
  padding: ${spacers.ss3};
`;

export const AlertBox = styled.View`
  position: ${isWeb ? "fixed" : "absolute"};
  bottom: 40px;
  left: 20px;
  right: 20px;
  justify-content: center;
`;

export const AlertContainer = styled.View<{ color?: string }>`
  background-color: ${({ color }) => color || colors.secondaryBlue};
  border-radius: 6px;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const AlertMessage = styled.TouchableOpacity`
  padding: ${spacers.ss6} ${spacers.ss6};
  flex: 1;
`;

export const AlertText = styled(Text2)`
  color: ${colors.black};
`;

export const AlertButton = styled.TouchableOpacity`
  color: ${colors.black};
  padding: ${spacers.ss4} ${spacers.ss6};
`;

export const ButtonText = styled(Text1)`
  color: ${colors.white};
  text-transform: uppercase;
  font-family: SFProDisplayBold;
`;
