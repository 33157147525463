import { format } from "date-fns";
import { Timestamp } from "firebase/firestore";

export const getFormattedTime = (item: string | number | Timestamp) => {
  const firebaseTimestamp = Number.isInteger((item as Timestamp)?.seconds)
    ? (item as Timestamp).seconds * 1000
    : 0;
  const timestamp = firebaseTimestamp || (item as number) || 0;
  return timestamp ? format(new Date(timestamp), "MM/dd/yy") : "-";
};
