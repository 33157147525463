import {
  WebBrowserPresentationStyle,
  openBrowserAsync,
} from "expo-web-browser";

import { makeExternalLinks } from "~/utils/external-links";

export const navigateToFAQ = async () => {
  await openBrowserAsync(
    `${makeExternalLinks().FAQS}#p_C806E0B3CF904FA98A4CBB8B11995F91`,
    {
      presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
    }
  );
};
