import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Environment, User, Profile, AppRoles, ProviderData } from "./types";
import { isWeb } from "~/utils/platform";

const getState = (state: RootState) => state.user;

export const getUser: (state: RootState) => User | null = createSelector(
  getState,
  (state) => state?.user
);

export const getProfile: (state: RootState) => Profile | null = createSelector(
  getState,
  (state) => state?.profile
);

export const getIsEmailVerified: (state: RootState) => boolean = createSelector(
  [getState, getUser],
  (state, user) => user?.emailVerified || state?.emailVerified
);

export const getIsLoggedIn: (state: RootState) => boolean = createSelector(
  getUser,
  (user) => !!user
);

export const getIsAdmin: (state: RootState) => boolean = createSelector(
  [getUser, getProfile],
  (user, profile) => !!user?.isAdmin || profile?.appRole === AppRoles.Admin
);

export const getIsEditor: (state: RootState) => boolean = createSelector(
  [getIsAdmin, getProfile],
  (isAdmin, profile) => isAdmin || profile?.appRole === AppRoles.Editor
);

export const getIsDevToolsAvailable: (state: RootState) => boolean =
  createSelector(
    [getIsAdmin, getIsEditor],
    (isAdmin, isEditor) => isAdmin || isEditor
  );

export const getPermissions: (state: RootState) => string[] = createSelector(
  [getProfile],
  (profile) => profile?.permissions || []
);

export const getHasPermission: (state: RootState, name: string) => boolean =
  createSelector([getPermissions, (_, props) => props], (permissions, name) =>
    permissions.includes(name)
  );

export const getEnvironment: (state: RootState) => Environment = createSelector(
  getState,
  (state) => state.environment || Environment.Production
);

export const getIsStaging: (state: RootState) => boolean = createSelector(
  getEnvironment,
  (environment) => environment === Environment.Staging
);

export const getUserId: (state: RootState) => string = createSelector(
  getState,
  (state) => state?.user?.uid || ""
);

export const getUserToken: (state: RootState) => string = createSelector(
  getState,
  (state) => state?.user?.stsTokenManager.accessToken || ""
);

export const getUserCustomerId: (state: RootState) => string = createSelector(
  [getProfile, getEnvironment],
  (profile, env) => profile?.[`customerId_${env}`] || ""
);

export const getUserDisplayName: (state: RootState) => string = createSelector(
  getState,
  (state) => {
    const userDisplayName = state?.user?.displayName;
    if (typeof userDisplayName === "string" && userDisplayName) {
      return userDisplayName;
    }
    return (
      state?.user?.reloadUserInfo?.displayName ||
      state?.user?.providerData?.find((item) => !!item.displayName)
        ?.displayName ||
      ""
    );
  }
);

export const getUserFirstName: (state: RootState) => string = createSelector(
  [getState, getUserDisplayName],
  (state, displayName) => {
    return state?.profile?.firstName || displayName?.split(" ")[0] || "";
  }
);

export const getUserLastName: (state: RootState) => string = createSelector(
  [getState, getUserDisplayName],
  (state, displayName) => {
    return state?.profile?.lastName || displayName?.split(" ")[1] || "";
  }
);

export const getUserFullName: (state: RootState) => string = createSelector(
  [getUserFirstName, getUserLastName],
  (firstName, lastName) => `${firstName} ${lastName}`
);

export const getProfileImage: (state: RootState) => string = createSelector(
  getState,
  (state) => state?.profile?.image || state?.user?.photoURL || ""
);

export const getHasProfile: (state: RootState) => boolean = createSelector(
  getState,
  (state) => !!state?.profile
);

export const getUserEmail: (state: RootState) => string = createSelector(
  getState,
  (state) => state?.user?.email || ""
);

export const getUserProviderData: (state: RootState) => ProviderData[] =
  createSelector(getState, (state) => state?.user?.providerData || []);

export const getIsSocialProvider: (state: RootState) => boolean =
  createSelector(
    getUserProviderData,
    (data) =>
      !!data.find(({ providerId }) =>
        ["google.com", "apple.com"].includes(providerId)
      )
  );

export const getFullName: (state: RootState) => string = createSelector(
  getState,
  (state) =>
    [state?.profile?.firstName, state?.profile?.lastName].join(" ").trim()
);

export const getAppUsage: (state: RootState) => string[] = createSelector(
  getState,
  (state) => state?.profile?.usage || []
);

export const getAppRoles: (state: RootState) => string[] = createSelector(
  getState,
  (state) => state?.profile?.roles || []
);

export const getAppAttendance: (state: RootState) => string = createSelector(
  getState,
  (state) => state?.profile?.attendance || ""
);

export const getAppChurchUsage: (state: RootState) => string | undefined =
  createSelector(getState, (state) => state?.profile?.churchUsage);

export const getEmailOptIn: (state: RootState) => string | undefined =
  createSelector(getProfile, (profile) => profile?.emailOptIn);

export const getShouldAskForFeedback: (state: RootState) => boolean =
  createSelector(
    getState,
    ({ firstLoginDate, hasUserBeenPromptedForFeedback }) => {
      if (isWeb || hasUserBeenPromptedForFeedback || !firstLoginDate)
        return false;

      const today = new Date();
      const diffTime = Math.abs(
        today.getTime() - new Date(firstLoginDate).getTime()
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // check if 30 days have passed since the first login
      return diffDays >= 30;
    }
  );
