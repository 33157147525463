import * as React from "react";

const CardField = () => null;

const PlatformPay = () => null;

const CardFieldInput = () => null;

const StripeProvider = ({ children }: { children: React.ReactNode }) =>
  children;

const useConfirmPayment = () => ({
  confirmPayment: () => ({
    paymentIntent: "",
    error: null,
  }),
});

const createPaymentMethod = () => {};

const confirmSetupIntent = () => {};

const usePlatformPay = () => ({
  isPlatformPaySupported: () => {},
  confirmPlatformPayPayment: () => {},
});

export {
  CardField,
  CardFieldInput,
  StripeProvider,
  useConfirmPayment,
  createPaymentMethod,
  confirmSetupIntent,
  PlatformPay,
  usePlatformPay,
};
