export const messages = {
  key: {
    id: "options.key",
    defaultMessage: "Key",
  },
  value: {
    id: "options.value",
    defaultMessage: "Value",
  },
  addOption: {
    id: "options.add",
    defaultMessage: "Add option",
  },
  removeOption: {
    id: "options.remove",
    defaultMessage: "Remove",
  },
};
