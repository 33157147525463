export const messages = {
  scheduleTimespan: {
    id: "in-app.schedule-timespan",
    defaultMessage: "From {{from}} to {{to}}",
  },
  deleteConfirmation: {
    id: "in-app.delete-confirmation",
    defaultMessage: "Delete Item",
  },
  deleteConfirmationDescription: {
    id: "in-app.delete-description",
    defaultMessage: "Are you sure you want to proceed?",
  },
  empty: {
    id: "in-app.empty",
    defaultMessage: "No results found",
  },
  createNew: {
    id: "in-app.create-new",
    defaultMessage: "Create new in-app message",
  },
};
