import { createSelector } from "@reduxjs/toolkit";

import { getUserId } from "~/state/user/selectors";
import { getUsers } from "~/state/users/selectors";
import { User } from "~/state/users/types";
import { MessageType } from "~/state/chat/types";

import { RootState } from "../store";
import { Group, GroupMessage, Roles } from "./types";

const getState = (state: RootState) => state.groups;

export const getGroups: (state: RootState) => Group[] = createSelector(
  getState,
  (state) => state?.data || []
);

export const getPendingInviteCode: (state: RootState) => Group[] =
  createSelector(getState, (state) => state?.inviteCode || "");

export const getGroupsOrderedByTime: (state: RootState) => Group[] =
  createSelector(getGroups, (groups) =>
    [...groups]
      .sort((a, b) => b?.createdAt - a?.createdAt)
      .map((data) => ({
        ...data,
        timestamp: data.recentMessage?.sentAt || data.createdAt,
      }))
  );

export const getOrderedGroups: (state: RootState) => Group[] = createSelector(
  getGroupsOrderedByTime,
  (groups) => {
    return [...groups].sort((a, b) => b?.timestamp - a?.timestamp);
  }
);

export const getGroupById: (
  state: RootState,
  groupId: string
) => Group | undefined = createSelector(
  [getGroups, (_, props) => props],
  (groups, groupId) => {
    return groups.find((group: Group) => group.id === groupId);
  }
);

export const getGroupsWithPlanId: (
  state: RootState,
  planId: string
) => Group[] = createSelector(
  [getGroups, (_, props) => props],
  (groups, planId) => {
    return groups.filter((group: Group) => group.plans.includes(planId));
  }
);

export const getGroupsMessages: (state: RootState) => GroupMessage[] =
  createSelector(
    [getOrderedGroups, getUserId, getUsers],
    (groups, userId, usersData) =>
      groups
        .map(
          ({
            id,
            name = "",
            imageUri = "",
            recentMessage,
            members,
            timestamp,
          }) => {
            const senderId = recentMessage?.sentBy;
            const userData = usersData?.find((users) => users.id === senderId);
            const userMemberData = members?.find(
              (member) => member.id === userId
            );

            const text = recentMessage?.content || "";
            const type = recentMessage?.type || MessageType.Text;
            const isDeleted = recentMessage?.isDeleted;
            const lastUserActivity = userMemberData?.lastActivity || 0;
            const hasUserRead = lastUserActivity > timestamp;

            const senderName = userData?.firstName || "";
            const isCurrentUser = senderId === userId;
            const isRead = hasUserRead || !text || isCurrentUser;

            return {
              id,
              senderName,
              text,
              type,
              timestamp,
              groupId: id,
              groupName: name,
              groupUri: imageUri,
              isCurrentUser,
              isRead,
              isDeleted,
            };
          }
        )
        .sort((a, b) => b.timestamp - a.timestamp)
  );

export const getGroupsMembers: (state: RootState) => string[] = createSelector(
  getGroups,
  (data) => {
    const uniqueMembers = new Set(
      data.map((group) => (group.members || []).map(({ id }) => id)).flat()
    );
    return Array.from(uniqueMembers);
  }
);

export const getGroupMembersById: (
  state: RootState,
  groupId: string
) => User[] = createSelector(
  [getGroups, getUsers, (_, groupId) => groupId],
  (groups, users, groupId) => {
    const group = groups.find(({ id }) => groupId === id);
    const members = group?.members || [];

    return members
      .map(({ id, role }) => {
        const userData = users.find((user) => user.id === id) as User;
        if (!userData) {
          return;
        }
        return {
          ...userData,
          isLeader: role === Roles.Leader || role === Roles.Admin,
          id,
        };
      })
      .filter(item => !!item)
      .sort((a, b) => (!a || !b) ? 0 : Number(b?.isLeader) - Number(a?.isLeader) || a?.firstName?.localeCompare(b?.firstName)) as User[];
  }
);

export const getRecentMessageByGroupId: (
  state: RootState,
  groupId: string
) => string = createSelector(
  [getGroups, (_, groupId) => groupId],
  (groups, groupId) => {
    const group = groups.find(({ id }) => groupId === id);
    return group?.recentMessage?.id || "";
  }
);

export const getIsGroupLeaderByGroupId: (
  state: RootState,
  groupId: string
) => boolean = createSelector(
  [getGroupMembersById, getUserId],
  (members, userId) => {
    return !!members.find(({ id, isLeader }) => userId === id && isLeader);
  }
);

export const getGroupLeadersCount: (
  state: RootState,
  groupId: string
) => number = createSelector([getGroupMembersById], (members) => {
  return members.filter(({ isLeader }) => !!isLeader).length;
});

export const getGroupsWithoutPlanId: (
  state: RootState,
  planId: string
) => Group[] = createSelector(
  [getGroupsOrderedByTime, (_, planId) => planId],
  (groups, planId) => {
    return groups.filter(({ plans = [] }) => !plans.includes(planId));
  }
);

export const getLeaderGroupsWithoutPlan: (
  state: RootState,
  planId?: string
) => Group[] = createSelector(
  [getGroupsOrderedByTime, getUserId, (_, planId) => planId],
  (groups, userId, planId) => {
    return groups.filter(
      ({ plans = [], members = [] }) =>
        !plans.includes(planId) &&
        !!members.find(({ id, role }) => id === userId && (role === Roles.Leader || role === Roles.Admin))
    );
  }
);

export const getUnreadMessagesCount: (state: RootState) => number =
  createSelector([getGroups, getUserId], (groups, userId) => {
    const count: number = groups.reduce((acc, group) => {
      const totalMessages = group.totalMessages || 0;
      const userMessagesCount = group?.readMessages?.[userId] || 0;
      const unreadMessages = totalMessages - userMessagesCount;

      return acc + unreadMessages;
    }, 0);
    return Math.max(0, count);
  });

export const getUnreadGroupMessagesCount: (
  state: RootState,
  groupId: string
) => number = createSelector(
  [getGroups, getUserId, (_, groupId) => groupId],
  (groups, userId, groupId) => {
    const group = groups.find(({ id }) => id === groupId);
    if (!group) {
      return 0;
    }
    const totalMessages = group.totalMessages || 0;
    const userMessagesCount = group?.readMessages?.[userId] || 0;
    const count = totalMessages - userMessagesCount;
    return Math.max(0, count);
  }
);
