import * as FileSystem from "~/utils/file-system";
import { nativeApplicationVersion, nativeBuildVersion } from "expo-application";
import Constants from "expo-constants";

import { isWeb } from "~/utils/platform";

import * as routes from "./routes";

// TODO: Double check the final URLs after publishing
export const APP_STORE_URL = "https://apps.apple.com/us/app/bep/id1663113496";
export const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=org.ag.bep";
// Note: keep in sync with the values in the app.config.js
export const BUNDLE_ID = "org.ag.bep";
export const WEB_APP_URL = "https://app.bibleengagementproject.com";
export const AUTH_CLIENT_ID = "com.bibleengagementproject.app";
export const AUTH_REDIRECT_URI = `${WEB_APP_URL}/__/auth/handler`;
export const FIREBASE_WEB_APP_URL = "https://bep-rc.firebaseapp.com";
export const FUNCTIONS_ENDPOINT =
  "https://us-central1-bep-rc.cloudfunctions.net";
export const PLACES_API_URL =
  "https://maps.googleapis.com/maps/api/place/autocomplete/json";
export const STORAGE_URL =
  "https://firebasestorage.googleapis.com/v0/b/bep-rc.appspot.com/o";

export const EXTERNAL_LINKS = {
  en: {
    MARKETING: "https://bibleengagementproject.com",
    FAQS: "https://bibleengagementproject.com/en/FAQ",
    CONTACT_US: "https://bibleengagementproject.com/en/Contact",
    PRIVACY_POLICY: "https://ag.org/en/Privacy",
    TERMS_AND_CONDITIONS: "https://ag.org/en/Terms%20of%20Use",
    GIVE: "https://giving.ag.org/donate/bep",
  },
  es: {
    MARKETING: "https://bibleengagementproject.com/es-ES",
    FAQS: "https://bibleengagementproject.com/es-ES/FAQ",
    CONTACT_US: "https://bibleengagementproject.com/es-ES/Contact",
    PRIVACY_POLICY: "https://ag.org/es-ES/Privacy",
    TERMS_AND_CONDITIONS: "https://ag.org/es-ES/Terms%20of%20Use",
    GIVE: "https://giving.ag.org/donate/bep",
  },
};
export const WEB_GEOCODING_ENDPOINT =
  "https://api.opencagedata.com/geocode/v1/json";

export const MEASUREMENT_ID = "G-B7Z3SEL10H";

export const BIBLE_DIRECTORY = FileSystem.documentDirectory + "bep/bibles";

export const FILES_DIRECTORY =
  FileSystem.documentDirectory + "bep/files/downloads";

export const FS_OPTIONS = { encoding: FileSystem.EncodingType?.UTF8 };

export const SUPPORTED_BIBLE_VERSIONS = [
  "NASB",
  "engKJV",
  "NVI-S",
  "NIV11",
  "RVR09",
  "NLT",
  "NTV",
  "ESV16",
];

export const HEADER_BAR_HEIGHT = isWeb ? 72 : 32;
export const HEADER_BAR_TOP_OFFSET = 20;

export const APP_VERSION =
  `${nativeApplicationVersion}.${nativeBuildVersion}` ??
  Constants.expoConfig?.version!;

export const stripeProps = {
  urlScheme: "org.ag.bep",
  merchantIdentifier: "merchant.org.ag.bep",
};

export const CONTRIBUTIONS_EMAIL = "bep@myhealthychurch.org";
export const CONTRIBUTIONS_PHONE = "(800) 545-2760";
export const CONTRIBUTIONS_RETURN_URL = `${WEB_APP_URL}/${routes.settingsModal}?tab=${routes.payItForward}`;
