export const messages = {
  title: {
    id: "profile.title",
    defaultMessage: "Profile",
  },
  contactInfo: {
    id: "profile.contact-info",
    defaultMessage: "Contact Info",
  },
  password: {
    id: "profile.password",
    defaultMessage: "Password",
  },
  changePassword: {
    id: "profile.change-password",
    defaultMessage: "Change Password",
  },
  emailSettings: {
    id: "profile.email-settings",
    defaultMessage: "Promotional Emails",
  },
  emailOptOut: {
    id: "profile.email-opt-out",
    defaultMessage: "Keep me updated",
  },
  namePlaceholder: {
    id: "profile.name-placeholder",
    defaultMessage: "Enter your name",
  },
  deleteAccount: {
    id: "settings.delete-account",
    defaultMessage: "Delete Account",
  },
  deleteAccountConfirmation: {
    id: "settings.delete-account-confirmation",
    defaultMessage: "Are you sure you want to permanently delete your account?",
  },
  deleteAccountConfirmationDescription: {
    id: "settings.delete-account-confirmation-description",
    defaultMessage:
      "By deleting your account, you will lose all progress, notes, messages, and any data associated with this account.",
  },
  deleteAccountConfirmationCancel: {
    id: "settings.delete-account-confirmation-cancel",
    defaultMessage: "Cancel",
  },
  deleteAccountConfirmationDelete: {
    id: "settings.delete-account-confirmation-delete",
    defaultMessage: "Delete",
  },
};
