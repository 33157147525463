import * as React from "react";
import {
  AutocompleteRequestType,
  PlaceType,
  SearchType,
} from "react-native-google-places-autocomplete";

import { TextInput } from "~/components/text-input";
import { getValuesFromMap } from "~/screens/create-profile/components/utils";

import { messages } from "./intl";
import { Container, Options, Option, OptionText } from "./styles";

type Props = {
  onPress: (data: string) => void;
  type: SearchType | PlaceType | AutocompleteRequestType | "state";
  label: MessageDescriptor;
  value?: string;
  onChangeText: (data: string) => void;
  hasBlur?: boolean;
  ignoreFilter?: boolean;
  options: Map<string, string>;
};

export const LocationInput = React.memo<Props>(
  ({
    label = messages.defaultLabel,
    value = "",
    onPress,
    onChangeText,
    options: defaultOptions,
    ignoreFilter,
  }) => {
    const [isVisible, setIsVisible] = React.useState(false);

    const options = getValuesFromMap(defaultOptions, value, ignoreFilter);

    const handleOnPress = (data: string) => {
      onPress(data);
      setIsVisible(false);
    };

    return (
      <>
        <TextInput
          label={label}
          value={value || ""}
          onChangeText={(text: string) => {
            onChangeText(text);
            setIsVisible(true);
          }}
          autoCorrect={false}
          autoCapitalize="none"
          returnKeyType="done"
          numberOfLines={1}
          onFocus={() => setIsVisible(true)}
        />

        {isVisible && options.length && !!value ? (
          <Container>
            <Options>
              {options.slice(0, 10).map((item) => {
                return (
                  <Option
                    key={item.value}
                    onPress={() => handleOnPress(item.value)}
                  >
                    <OptionText>{item.value}</OptionText>
                  </Option>
                );
              })}
            </Options>
          </Container>
        ) : null}
      </>
    );
  }
);
