import React from "react";

import { messages } from "./intl";
import { Container, Dot, Text } from "./styles";
import { Status } from "./types";

interface Props {
  status: Status;
}

const messageMap = new Map([
  [Status.Inactive, messages.inactive],
  [Status.Active, messages.active],
  [Status.Expired, messages.expired],
]);

export const StatusTag = React.memo<Props>(({ status }) => {
  return (
    <Container>
      <Dot status={status} />
      <Text>{messageMap.get(status) || ""}</Text>
    </Container>
  );
});
