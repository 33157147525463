import { SagaIterator } from "redux-saga";
import { call, select, put, all, take } from "redux-saga/effects";

import {
  collection,
  getDocs,
  DocumentSnapshot,
  Timestamp,
} from "firebase/firestore";

import { database } from "<config>/firebase";

import { getInAppNotificationsCollection } from "~/constants/collections";
import { handleError } from "~/utils/logger";
import { setIsUserReady } from "~/state/user/actions";
import { getEnvironment } from "~/state/user/selectors";
import { splashScreenFinishedLoading } from "~/state/startup/slice";

import { NotificationData } from "./types";
import { getNotificationsBlacklist } from "./selectors";
import { setNotification, resetNotification } from "./slice";

const getFirebaseTime = (time: Timestamp | string | undefined) => {
  try {
    if (!time) {
      return 0;
    }
    if (typeof time === "string") {
      return new Date(time).getTime();
    }
    return time.toDate().getTime();
  } catch (e) {
    return 0;
  }
};

export const getInAppNotificationsFn = function* (): SagaIterator {
  try {
    const blacklist = yield select(getNotificationsBlacklist);
    const env = yield select(getEnvironment);
    const collectionName = getInAppNotificationsCollection(env);

    const snapshots = yield call(
      // @ts-ignore
      getDocs,
      collection(database, collectionName)
    );

    const data = snapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as NotificationData[];

    const now = new Date().getTime();

    const notification = data.find((item) => {
      const schedule = getFirebaseTime(item?.schedule);
      const expiration = getFirebaseTime(item?.expiration);

      return (
        schedule < now &&
        expiration > now &&
        !blacklist.includes(item?.id) &&
        !!item?.isActive
      );
    });

    if (notification && notification.id) {
      yield put(setNotification(notification));
      return;
    }
    yield put(resetNotification());
  } catch (e) {
    yield put(resetNotification());
    yield call(handleError, e);
  }
};

export const getInAppNotificationsSaga = function* () {
  yield all([
    take(setIsUserReady.type),
    take(splashScreenFinishedLoading.type),
  ]);
  yield call(getInAppNotificationsFn);
};
