import React, { useMemo } from "react";
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import * as routes from "~/constants/routes";
import { PostAuthParamList } from "~/navigation/post-auth";
import { IconWithBadge } from "~/components/icon/icon-with-badge";
import { getEnvironment, getProfileImage } from "~/state/user/selectors";
import { getUnreadMessagesCount } from "~/state/groups/selectors";
import { IconSizes } from "~/components/icon";
import { BackButton } from "~/components/back-button";
import { Avatar } from "~/components/avatar";
import { TutorialStep } from "~/components/tutorial-step";
import { useAppSelector } from "~/state/hooks";
import { getElementColorBasedOnDayTime } from "~/utils/theme";
import { HEADER_BAR_TOP_OFFSET } from "~/constants";
import { colors } from "~/styles/theme";
import { getHasDownloads, getPlanLogo } from "~/state/flamelink/selectors";
import { SessionRouteProp } from "~/screens/session/types";
import { Environment } from "~/state/user/types";
import { isWeb } from "~/utils/platform";
import { useTutorial } from "~/state/tutorial";
import { getGroupsSeen, getTutorialStep } from "~/state/tutorial/selectors";
import { getRemoteConfigValue } from "~/state/config";

import { WebHeader } from "./web";

import {
  HeaderAvatar,
  HeaderBarBackButtonContainer,
  HeaderBarContainer,
  HeaderIconsContainer,
  HeaderIconWrapper,
  MiniLogo,
  HeaderBarTitle,
  Badge,
  BadgeButton,
  BadgeText,
} from "./styles";
import { messages } from "./intl";
import { formatMessage } from "~/utils/translation";

interface HeaderBarProps {
  iconColor?: string;
  backButtonText?: MessageDescriptor;
  noMargin?: boolean;
  withBackButton?: boolean;
  withChatButton?: boolean;
  withCastButton?: boolean;
  withLightButtons?: boolean;
  withPlanLogo?: boolean;
  title?: string;
  onBackPress?: () => void;
  isSticky?: boolean;
  hasShadow?: boolean;
  isPreAuth?: boolean;
}

type NavigationProp = NativeStackScreenProps<
  PostAuthParamList,
  "messages" | "settings.modal"
>;

export const HeaderBar = ({
  iconColor,
  backButtonText,
  noMargin,
  withBackButton = true,
  withChatButton = true,
  withCastButton = false,
  withLightButtons = false,
  withPlanLogo = false,
  title,
  onBackPress,
  isSticky,
  hasShadow,
  isPreAuth,
}: HeaderBarProps) => {
  const { params } = useRoute<SessionRouteProp>();
  const navigation = useNavigation<NavigationProp["navigation"]>();
  const safeAreaInsets = useSafeAreaInsets();

  const planLogo = useAppSelector((state) =>
    getPlanLogo(state, params?.planId ?? "")
  );

  const marginTop = noMargin
    ? HEADER_BAR_TOP_OFFSET
    : safeAreaInsets.top + HEADER_BAR_TOP_OFFSET;

  if (isWeb)
    return (
      <WebHeader
        iconColor={iconColor}
        withChatButton={withChatButton}
        withLightButtons={false}
        isSticky={isSticky}
        hasShadow={hasShadow}
        isPreAuth={isPreAuth}
      />
    );

  return (
    <HeaderBarContainer style={{ marginTop }}>
      <HeaderBarBackButtonContainer>
        {withBackButton && navigation.canGoBack() && (
          <BackButton
            hasText
            text={backButtonText}
            lightBackButton={withLightButtons}
            onPress={onBackPress}
          />
        )}
      </HeaderBarBackButtonContainer>

      {withPlanLogo && planLogo && <MiniLogo source={{ uri: planLogo }} />}

      {title && <HeaderBarTitle>{title}</HeaderBarTitle>}

      <HeaderIcons
        iconColor={iconColor}
        withChatButton={withChatButton}
        withCastButton={withCastButton}
        withLightButtons={withLightButtons}
      />
    </HeaderBarContainer>
  );
};

export const HeaderIcons = ({
  iconColor,
  withChatButton = true,
  withCastButton = false,
  withLightButtons = false,
}: Pick<
  HeaderBarProps,
  "iconColor" | "withChatButton" | "withCastButton" | "withLightButtons"
>) => {
  const elementColor = getElementColorBasedOnDayTime();
  const unreadMessagesCount = useAppSelector(getUnreadMessagesCount);
  const navigation = useNavigation<NavigationProp["navigation"]>();

  const profileImage = useAppSelector(getProfileImage);
  const environment = useAppSelector(getEnvironment);
  const hasDownloads = useAppSelector(getHasDownloads);

  const route = useRoute();
  const isFocused = useIsFocused();
  const { showTutorial } = useTutorial(route.name, isFocused);

  const iconColors = useMemo(
    () => (withLightButtons ? colors.white : iconColor ?? elementColor),
    [withLightButtons, elementColor, iconColor]
  );

  const onProfilePress = React.useCallback(() => {
    navigation.navigate(routes.settingsModal);
  }, [navigation]);

  const onGivePress = React.useCallback(() => {
    navigation.navigate(routes.payItForward);
  }, [navigation]);

  const goToMessages = React.useCallback(() => {
    navigation.navigate(routes.messages);
  }, [navigation]);

  const profileStep = useAppSelector((state) =>
    getTutorialStep(state, "home-profile")
  );
  const downloadsStep = useAppSelector((state) =>
    getTutorialStep(state, "home-downloads")
  );
  const groupsSeen = useAppSelector(getGroupsSeen);

  const isGivingButtonEnabled = useAppSelector((state) =>
    getRemoteConfigValue(state, "givingButtonEnabled")
  );

  React.useEffect(() => {
    // Show the profile message the second time user opens the app
    if (profileStep && groupsSeen.includes("library")) {
      showTutorial(profileStep);
      // Show the downloads message after user downloaded any files
    } else if (
      hasDownloads &&
      downloadsStep &&
      groupsSeen.includes("home-profile")
    ) {
      showTutorial(downloadsStep);
    }
  }, [showTutorial, profileStep, hasDownloads, downloadsStep, groupsSeen]);

  const giveMessage = formatMessage(messages.give).toUpperCase();

  return (
    <HeaderIconsContainer>
      {environment === Environment.Staging ? (
        <Badge color={iconColors}>
          <BadgeText color={iconColors}>STG</BadgeText>
        </Badge>
      ) : null}

      {isGivingButtonEnabled ? (
        <BadgeButton onPress={onGivePress} color={iconColors}>
          <BadgeText color={iconColors}>{giveMessage}</BadgeText>
        </BadgeButton>
      ) : null}

      {withChatButton ? (
        <HeaderIconWrapper>
          <IconWithBadge
            badgeCount={unreadMessagesCount}
            onPress={goToMessages}
            name="chat"
            size={IconSizes.Medium}
            color={iconColors}
          />
        </HeaderIconWrapper>
      ) : null}

      <TutorialStep id={["HOME.ACTION.PROFILE1", "HOME.ACTION.PROFILE2"]}>
        <HeaderAvatar onPress={onProfilePress}>
          <Avatar isSettings size={IconSizes.Large} uri={profileImage} />
        </HeaderAvatar>
      </TutorialStep>
    </HeaderIconsContainer>
  );
};
