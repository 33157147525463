import React from "react";
import { GestureResponderEvent } from "react-native";
import { CommonActions, useNavigation } from "@react-navigation/native";
import { ModalHeader } from "~/components/modal-header";
import {
  SettingsListItem,
  SettingsListItemContainer,
  SettingsListItemLabel,
  SettingsScreen,
} from "~/components/settings";
import { formatMessage, getCurrentLocale } from "~/utils/translation";
import { useChangeLanguages } from "~/utils/hooks/use-change-languages";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { useVideoQuality } from "~/utils/hooks/use-video-quality";
import { useFontSize } from "~/utils/hooks/use-font-size";
import {
  getAppLanguage,
  setAppLanguage,
  getLanguagesToDisplay,
} from "~/state/settings";
import * as routes from "~/constants/routes";
import { messages } from "./intl";
import { mapAppLocaleToLabel, mapFlamelinkLocaleToLabel } from "./utils";
import { isWeb } from "~/utils/platform";

export const AppSettings = () => {
  const [shouldResetStackWhenDone, setShouldResetStackWhenDone] =
    React.useState(false);
  const navigation = useNavigation();
  const languagesToDisplay = useAppSelector(getLanguagesToDisplay);
  const appLanguage = useAppSelector(getAppLanguage);
  const { changeResourcesLanguages, changeAppLanguage } = useChangeLanguages();
  const { selectVideoQuality, selectedVideoQuality } = useVideoQuality();
  const { selectFontSize, selectedFontSize } = useFontSize();
  const dispatch = useAppDispatch();

  const onDonePress = React.useCallback(() => {
    if (shouldResetStackWhenDone) {
      // re-mounts the navigation to reset the language in all the screens
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{ name: routes.homeTab, params: { screen: routes.home } }],
        })
      );
      // cleans the modal stack to be able to see the changes
      navigation.getParent()?.goBack();
    } else {
      navigation.goBack();
    }
  }, [navigation, shouldResetStackWhenDone]);

  const onAppLanguageChange = React.useCallback(
    (event: GestureResponderEvent, position: DOMRect) =>
      changeAppLanguage(
        (language: string) => {
          setShouldResetStackWhenDone(true);
          dispatch(setAppLanguage(language));
        },
        event,
        position
      ),
    [changeAppLanguage, dispatch]
  );

  return (
    <SettingsScreen screenName="app-settings" key={appLanguage}>
      {!isWeb ? (
        <ModalHeader
          onLeftPress={onDonePress}
          onRightPress={onDonePress}
          title={messages.title}
        />
      ) : null}

      <SettingsListItemContainer>
        <SettingsListItemLabel
          label={formatMessage(messages.resourceLanguages)}
        />

        <SettingsListItem
          title={languagesToDisplay?.map(mapFlamelinkLocaleToLabel).join(", ")}
          type="select"
          isFirstItem
          isLastItem
          onPress={changeResourcesLanguages}
        />
      </SettingsListItemContainer>

      <SettingsListItemContainer>
        <SettingsListItemLabel label={formatMessage(messages.appLanguage)} />

        <SettingsListItem
          title={mapAppLocaleToLabel(getCurrentLocale())}
          type="select"
          isFirstItem
          isLastItem
          onPress={onAppLanguageChange}
        />
      </SettingsListItemContainer>

      <SettingsListItemContainer>
        <SettingsListItemLabel label={formatMessage(messages.videoQuality)} />

        <SettingsListItem
          title={selectedVideoQuality}
          type="select"
          isFirstItem
          isLastItem
          onPress={(event: GestureResponderEvent, position: DOMRect) =>
            selectVideoQuality({ saveSettings: true }, event, position, true)
          }
        />
      </SettingsListItemContainer>

      <SettingsListItemContainer>
        <SettingsListItemLabel label={formatMessage(messages.fontSize)} />

        <SettingsListItem
          title={selectedFontSize}
          type="select"
          isFirstItem
          isLastItem
          onPress={selectFontSize}
        />
      </SettingsListItemContainer>
    </SettingsScreen>
  );
};
