import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { InAppNotification } from "./types";

const getState = (state: RootState) => state.inAppNotificationsData;

export const getInAppNotifications: (state: RootState) => InAppNotification[] =
  createSelector(getState, (state) => state?.data || []);

export const getInAppNotificationById: (
  state: RootState,
  id: string
) => InAppNotification | undefined = createSelector(
  [getInAppNotifications, (_, props) => props],
  (data, id) => data.find((item) => item.id === id)
);
