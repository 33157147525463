export const messages = {
  otherAmount: {
    id: "pif.amount.other",
    defaultMessage: "Other amount",
  },
  otherAmountValue: {
    id: "pif.amount.other-value",
    defaultMessage: "Other amount ({{value}})",
  },
  impact: {
    id: "pif.amount.impact",
    defaultMessage: "Your partnership makes an impact!",
  },
  continue: {
    id: "pif.amount.continue",
    defaultMessage: "Continue",
  },
};
