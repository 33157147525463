export const messages = {
  charactersPlural: {
    id: "count.characters.plural",
    defaultMessage: "{{value}}/{{limit}} characters",
  },
  charactersSingular: {
    id: "count.characters.singular",
    defaultMessage: "{{value}}/{{limit}} character",
  },
};
