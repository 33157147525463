import styled from "styled-components/native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { Text1, Text2, Text3 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

import { Status } from "./types";

const colorMap = new Map([
  [Status.Inactive, colors.gray300],
  [Status.Active, colors.emerald600],
  [Status.Expired, colors.amber600],
]);

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Dot = styled.View<{ status: Status }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ status }) => colorMap.get(status)};
  margin-right: ${spacers.ss3};
`;

export const Text = styled(Text1)`
  color: ${colors.gray500};
`;
