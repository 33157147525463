import styled from "styled-components/native";

import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const Container = styled.View`
  position: relative;
  z-index: 999;
`;

export const InputMask = styled.TouchableOpacity`
  cursor: pointer;
  z-index: 999;
`;

export const OptionBox = styled.ScrollView`
  background: ${colors.white};
  min-width: 300px;
  max-height: 240px;
  overflow: scroll;
  position: absolute;
  top: 65px;
  left: 0px;
  z-index: 999999;
  border: 1px solid ${colors.gray200};
`;

export const Option = styled.TouchableOpacity`
  padding: ${spacers.ss4} ${spacers.ss6};
`;

export const OptionText = styled(Text2)``;
