export const messages = {
  title: {
    id: "in-app-messages.title",
    defaultMessage: "In-App Messages",
  },
  subtitle: {
    id: "in-app-messages.subtitle",
    defaultMessage: "Dialogs or banners appearing within the app",
  },
  create: {
    id: "in-app-messages.create",
    defaultMessage: "Create In-App Message",
  },
  edit: {
    id: "in-app-messages.edit",
    defaultMessage: "Edit In-App Message",
  },
};
