import uuid from "react-native-uuid";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  doc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
  DocumentSnapshot,
} from "firebase/firestore";
import NetInfo from "@react-native-community/netinfo";
import moment from "moment-timezone";

import { database } from "<config>/firebase";
import { getEnvironment, getUserId } from "~/state/user/selectors";

import {
  updateAnnouncements,
  addAnnouncement,
  editAnnouncement,
  deleteAnnouncement,
} from "./slice";
import {
  getAnnouncements,
  addAnnouncement as addAnnouncementAction,
  editAnnouncement as editAnnouncementAction,
  removeAnnouncement,
} from "./actions";
import {
  GetAnnouncementsAction,
  AddAnnouncementAction,
  DeleteAnnouncementAction,
  EditAnnouncementAction,
  Announcement,
} from "./types";
import { getAnnouncementsCollection } from "~/constants/collections";
import { handleError } from "~/utils/logger";

export function* getAnnouncementsFn({
  payload: { onSuccess, onError },
}: GetAnnouncementsAction): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    yield call(onSuccess);

    return;
  }

  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }
  try {
    const env = yield select(getEnvironment);
    const announcementsCollection = getAnnouncementsCollection(env);

    const q = query(collection(database, announcementsCollection));

    const snapshots = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const data = snapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as Announcement[];

    if (data && Array.isArray(data)) {
      yield put(updateAnnouncements(data));
      yield call(onSuccess);
    } else {
      throw new Error();
    }
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* addAnnouncementFn({
  payload: { title, body, emails, schedule, isActive, onSuccess, onError },
}: AddAnnouncementAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const announcementsCollection = getAnnouncementsCollection(env);

    const userId = yield select(getUserId);
    const id = uuid.v4() as string;
    const lastUpdated = new Date().getTime();
    const expiration = moment(schedule).endOf("day").toISOString();

    const announcement = {
      id,
      title,
      body,
      emails,
      schedule,
      expiration,
      lastUpdated,
      userId,
      isActive,
    };

    yield call(
      // @ts-ignore
      setDoc,
      doc(database, announcementsCollection, id),
      announcement
    );

    // Update the local state to avoid an extra Firebase query
    yield put(addAnnouncement(announcement));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* editAnnouncementFn({
  payload: { id, title, body, emails, schedule, isActive, onSuccess, onError },
}: EditAnnouncementAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const announcementsCollection = getAnnouncementsCollection(env);
    const expiration = moment(schedule).endOf("day").toISOString();

    yield call(
      // @ts-ignore
      updateDoc,
      doc(database, announcementsCollection, id),
      { title, body, emails, schedule, expiration, isActive }
    );

    // Update the local state to avoid an extra Firebase query
    yield put(
      editAnnouncement({
        id,
        title,
        body,
        emails,
        schedule,
        isActive,
      })
    );
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* deleteAnnouncementFn({
  payload: { id, onSuccess, onError },
}: DeleteAnnouncementAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const announcementsCollection = getAnnouncementsCollection(env);

    yield call(
      // @ts-ignore
      deleteDoc,
      doc(database, announcementsCollection, id)
    );

    // Update the local state to avoid an extra Firebase query
    yield put(deleteAnnouncement(id));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const addAnnouncementsSaga = function* () {
  yield takeLatest(addAnnouncementAction.type, addAnnouncementFn);
};

export const getAnnouncementsSaga = function* () {
  yield takeLatest(getAnnouncements.type, getAnnouncementsFn);
};

export const editAnnouncementSaga = function* () {
  yield takeLatest(editAnnouncementAction.type, editAnnouncementFn);
};

export const deleteAnnouncementSaga = function* () {
  yield takeLatest(removeAnnouncement.type, deleteAnnouncementFn);
};
