import Svg from "react-native-svg";
import styled from "styled-components/native";
import { StyleSheet } from "react-native";

export const HeaderContainer = styled.View`
  flex-direction: column;
`;

export const Background = styled(Svg)`
  height: 400px;
  ${StyleSheet.absoluteFill}
`;
