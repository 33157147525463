import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { Reminder, ReminderTypes } from "./types";

const getState = (state: RootState) => state.reminders;

const getReminders: (state: RootState) => Reminder[] = createSelector(
  getState,
  (state) => state?.data || []
);

export const getLearningReminder: (state: RootState) => Reminder | undefined =
  createSelector(getReminders, (data) =>
    data.find((item) => item.type === ReminderTypes.Learning)
  );
