import * as React from "react";

import { HeaderBar } from "~/components/header-bar";
import { HeaderTitle } from "~/components/header-title";
import { Screen } from "~/components/screen";
import { OfflineBanner } from "~/components/offline-banner";
import { colors } from "~/styles/theme";
import { useAppSelector } from "~/state/hooks";
import { getGroupsOrderedByTime } from "~/state/groups/selectors";
import { GroupResourcesComponent } from "~/screens/group-resources";
import { GroupModalComponent } from "~/screens/group-modal";
import { GroupJoinComponent } from "~/screens/group-join";
import { EmptyGroup } from "~/components/empty-group";
import { Tabs } from "~/components/tabs";

import { GroupsList } from "./list/index.web";
import { GroupsActionButton } from "./action-button";
import {
  HeaderContainer,
  WebContainer,
  NavigationContainer,
  ContentContainer,
  WebEmptyContainer,
} from "./styles";
import { messages } from "./intl";
import { useModal } from "~/state/modal/hook.web";

export const Groups = () => {
  const data = useAppSelector(getGroupsOrderedByTime);
  const { showModal, hideModal } = useModal();

  const [groupId, setGroupId] = React.useState(data[0]?.id);
  const [tabIndex, setTabIndex] = React.useState(0);

  const onCreate = React.useCallback(() => {
    showModal(<GroupModalComponent onGoBack={hideModal} />);
  }, [showModal, hideModal]);

  const onJoin = React.useCallback(() => {
    showModal(<GroupJoinComponent onSuccess={hideModal} onError={hideModal} />);
  }, [showModal, hideModal]);

  const resetDefaultGroup = React.useCallback(() => {
    setGroupId(data[0]?.id);
  }, [setGroupId, data]);

  const hasData = data.length;

  return (
    <Screen screenName="groups" backgroundColor={colors.gray75} hasFixedHeader>
      <HeaderBar iconColor={colors.black} withBackButton={false} isSticky />

      <OfflineBanner />

      <WebContainer>
        <NavigationContainer>
          <HeaderContainer>
            <HeaderTitle>{messages.title}</HeaderTitle>

            <GroupsActionButton onCreate={onCreate} onJoin={onJoin} />
          </HeaderContainer>

          <GroupsList onPress={setGroupId} currentGroupId={groupId} />
        </NavigationContainer>

        <ContentContainer>
          {hasData ? (
            <>
              <Tabs
                titles={["Resources", "Details"]}
                activeIndex={tabIndex}
                onPress={setTabIndex}
              />
              {tabIndex === 0 ? (
                <GroupResourcesComponent groupId={groupId} key={groupId} />
              ) : null}
              {tabIndex === 1 ? (
                <GroupModalComponent
                  groupId={groupId}
                  key={groupId}
                  onGoBack={resetDefaultGroup}
                />
              ) : null}
            </>
          ) : (
            <WebEmptyContainer>
              <EmptyGroup onSuccess={setGroupId} />
            </WebEmptyContainer>
          )}
        </ContentContainer>
      </WebContainer>
    </Screen>
  );
};
