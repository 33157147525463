import React from "react";

import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { genericMessages } from "~/constants/intl";
import { formatMessage } from "~/utils/translation";
import { useAlert } from "~/components/alert";

import {
  Container,
  Box,
  HorizontalBox,
  DeleteButton,
  DeleteIcon,
  DeleteText,
} from "./styles";
import { messages } from "./intl";

interface Props {
  onCancel: () => void;
  onDelete: () => void;
  onSave: () => void;
  isSaveDisabled: boolean;
  isDeleteDisabled: boolean;
  hideDelete?: boolean;
}

export const ActionButtons = React.memo<Props>(
  ({
    onCancel,
    onDelete,
    onSave,
    isSaveDisabled,
    isDeleteDisabled,
    hideDelete,
  }) => {
    const { showAlert } = useAlert();

    const onDeletePress = () => {
      showAlert(
        formatMessage(messages.deleteConfirmation),
        formatMessage(messages.deleteConfirmationDescription),
        [
          {
            text: formatMessage(genericMessages.cancel),
          },
          {
            text: formatMessage(genericMessages.delete),
            onPress: onDelete,
            style: "destructive",
          },
        ]
      );
    };

    const onSavePress = React.useCallback(() => {
      showAlert(
        formatMessage(messages.deleteConfirmation),
        formatMessage(messages.deleteConfirmationDescription),
        [
          {
            text: formatMessage(genericMessages.cancel),
          },
          {
            text: formatMessage(genericMessages.save),
            onPress: onSave,
          },
        ]
      );
    }, []);

    return (
      <Container>
        <HorizontalBox>
          <Box>
            <Button
              type={ButtonTypes.Outlined}
              onPress={onCancel}
              text={genericMessages.cancel}
            />
          </Box>
          <Box>
            <Button
              type={ButtonTypes.Primary}
              onPress={onSave}
              text={genericMessages.save}
              isDisabled={isSaveDisabled}
            />
          </Box>
        </HorizontalBox>
        {!hideDelete ? (
          <Box>
            <DeleteButton onPress={onDeletePress} disabled={isDeleteDisabled}>
              <DeleteIcon />
              <DeleteText>{genericMessages.delete}</DeleteText>
            </DeleteButton>
          </Box>
        ) : null}
      </Container>
    );
  }
);
