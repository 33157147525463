type Action<T> = {
  type: string;
  payload: T;
  meta: any;
  error: any;
};

type Callbacks = {
  onSuccess: () => void;
  onError: () => void;
};

export enum NotificationType {
  BANNER = "banner",
  MODAL = "modal",
}

export enum LinkType {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export type InAppNotificationData = {
  type: NotificationType;
  title: string;
  description: string;
  image?: File;
  imageUrl?: string;
  buttonText?: string;
  navigateTo?: string;
  url?: string;
  options?: Record<string, string | number>;
  schedule: string | number;
  expiration: string | number;
  date?: string | number;
  linkType?: LinkType;
  route?: string;
  timeoutInSeconds?: number;
  maxTimes?: number;
  isActive?: boolean;
};

export type InAppNotification = {
  id: string;
} & InAppNotificationData;

export type InAppNotificationPayload = InAppNotificationData;

export type InAppNotificationsState = {
  data: InAppNotification[];
};

export type GetInAppNotificationsPayload = Callbacks;

export type AddInAppNotificationPayload = Callbacks & InAppNotificationPayload;

export type DeleteInAppNotificationPayload = Callbacks & {
  id: string;
};

export type EditInAppNotification = InAppNotification;

export type EditInAppNotificationPayload = Callbacks & EditInAppNotification;

export type GetInAppNotificationsAction = Action<GetInAppNotificationsPayload>;
export type AddInAppNotificationAction = Action<AddInAppNotificationPayload>;
export type EditInAppNotificationAction = Action<EditInAppNotificationPayload>;
export type DeleteInAppNotificationAction =
  Action<DeleteInAppNotificationPayload>;
