import * as React from "react";

import { Button } from "../buttons";

import { Container, Row, BottomSection, Text } from "./styles";
import { messages } from "./intl";
import { Footer } from "../footer";
import { CustomAmount } from "../custom-amount";

interface Props {
  amount: number;
  setAmount: (value: number, isCustom?: boolean) => void;
  onContinue: () => void;
  onOtherAmount: () => void;
  isCustomAmount: boolean;
  isEditingAmount: boolean;
}

const CURRENCY = "$";

export const AmountSelection = React.memo<Props>(
  ({
    amount,
    setAmount,
    onContinue,
    onOtherAmount,
    isCustomAmount,
    isEditingAmount,
  }) => {
    const otherText =
      isCustomAmount && amount
        ? {
            ...messages.otherAmountValue,
            values: { value: `${CURRENCY}${amount}` },
          }
        : messages.otherAmount;

    return (
      <Container>
        <Row>
          <Button
            text={`${CURRENCY}10`}
            isActive={!isCustomAmount && amount === 10}
            onPress={() => setAmount(10)}
            flex={1}
          />
          <Button
            text={`${CURRENCY}25`}
            isActive={!isCustomAmount && amount === 25}
            onPress={() => setAmount(25)}
            flex={1}
          />
          <Button
            text={`${CURRENCY}50`}
            isActive={!isCustomAmount && amount === 50}
            onPress={() => setAmount(50)}
            flex={1}
          />
        </Row>
        <Row>
          <Button
            text={`${CURRENCY}100`}
            isActive={!isCustomAmount && amount === 100}
            onPress={() => setAmount(100)}
            flex={1}
          />
          <Button
            text={otherText}
            onPress={onOtherAmount}
            flex={2}
            isActive={isCustomAmount}
          />
        </Row>

        <BottomSection>
          <Text>{messages.impact}</Text>
          <Button
            text={messages.continue}
            isActive={!!amount}
            isDisabled={!amount}
            onPress={onContinue}
          />
        </BottomSection>

        <Footer />

        {isEditingAmount ? (
          <CustomAmount
            value={`${amount}`}
            setValue={(value) => setAmount(Number(value), true)}
          />
        ) : null}
      </Container>
    );
  }
);
