import { createAction } from "@reduxjs/toolkit";

import {
  GetSubscriptionPayload,
  UpdateSubscriptionPayload,
  DeleteSubscriptionPayload,
  GetPaymentMethodsPayload,
  AddPaymentMethodPayload,
  UpdateDefaulPaymentMethodPayload,
  GetPaymentIntentsPayload,
  ConfirmPaymentPayload,
} from "./types";

export const getSubscriptions =
  createAction<GetSubscriptionPayload>("getSubscriptions");

export const updateSubscription =
  createAction<UpdateSubscriptionPayload>("updateSubscription");

export const deleteSubscription =
  createAction<DeleteSubscriptionPayload>("deleteSubscription");

export const getPaymentIntentsAction =
  createAction<GetPaymentIntentsPayload>("getPaymentIntents");

export const getPaymentMethodsAction =
  createAction<GetPaymentMethodsPayload>("getPaymentMethods");

export const addPaymentMethod =
  createAction<AddPaymentMethodPayload>("addPaymentMethod");

export const updateDefaultPaymentMethod =
  createAction<UpdateDefaulPaymentMethodPayload>("updateDefaultPaymentMethod");

export const confirmPayment =
  createAction<ConfirmPaymentPayload>("confirmPayment");
