import * as React from "react";
import { Linking, Modal } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  getShouldShowNotification,
  getCurrentInAppNotification,
  dismissNotification,
} from "~/state/in-app-notifications";
import { Button } from "~/components/button";
import { LinkType, NotificationType } from "~/state/in-app-notifications-setup";
import { colors } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { asyncLogEvent, events } from "~/utils/analytics";

import {
  Container,
  Content,
  ImageContent,
  TextContent,
  Title,
  Description,
  Image,
  ButtonContent,
  CloseButton,
  WebImage,
} from "./styles";
import { Icon, IconTypes } from "../icon";
import { useAlerts } from "~/state/alerts";

const INTERVAL = 1000;

export const InAppNotification = React.memo(() => {
  const shouldShowNotification = useAppSelector(getShouldShowNotification);
  const data = useAppSelector(getCurrentInAppNotification);
  const navigation = useNavigation();

  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  const [isVisible, setIsVisible] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState("");
  const [imageHeight, setImageHeight] = React.useState(1);
  const [containerWidth, setContainerWidth] = React.useState(1);

  const isBanner = data?.type === NotificationType.BANNER;

  const onDismiss = React.useCallback(
    (isOpen?: boolean) => {
      setIsVisible(false);
      dispatch(dismissNotification({ isOpen }));
      asyncLogEvent(events.IN_APP_BANNER_DISMISS);
    },
    [dispatch]
  );

  const onPress = React.useCallback(() => {
    onDismiss(true);
    asyncLogEvent(events.IN_APP_BANNER_CTA, data);
    if (data?.linkType === LinkType.EXTERNAL && data?.url) {
      Linking.openURL(data.url);
      return;
    }
    if (data?.linkType === LinkType.INTERNAL && data?.navigateTo) {
      // We need to wait until the Modal animation finishes before we potentially
      // navigate to another screen of modal type, otherwise they would block one another
      setTimeout(() => {
        // @ts-ignore
        navigation.navigate(data?.navigateTo, data?.options || {});
      }, 600);
    }
  }, [data, navigation, onDismiss]);

  React.useEffect(() => {
    let counter = 0;
    if (!data || data?.route !== currentRoute) {
      return;
    }

    if (data?.timeoutInSeconds) {
      const timeoutInterval = setInterval(() => {
        if (counter / 1000 > Number(data.timeoutInSeconds)) {
          setIsVisible(true);
          clearInterval(timeoutInterval);
        }
        counter = counter + INTERVAL;
      }, INTERVAL);
    } else {
      setIsVisible(true);
    }
  }, [data, currentRoute]);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("state", () => {
      const route = navigation?.getCurrentRoute();
      setCurrentRoute(route?.name);
    });

    return unsubscribe;
  }, [navigation]);

  React.useEffect(() => {
    if (!data || !isBanner) {
      return;
    }
    pushAlert({
      message: data.description,
      color: colors.secondaryBlue,
      isDismissable: true,
      onPress,
      onDismiss,
    });
  }, [data, isBanner, onPress]);

  if (!data || !shouldShowNotification || isBanner) {
    return null;
  }
  const ImageComponent = isWeb ? WebImage : Image;

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={() => {
        setIsVisible(!isVisible);
        onDismiss();
      }}
    >
      <Container>
        <CloseButton onPress={() => onDismiss()}>
          <Icon type={IconTypes.Close} color={colors.black} size={10} />
        </CloseButton>
        <Content
          onLayout={(event) => {
            const { width } = event.nativeEvent.layout;
            setContainerWidth(width);
          }}
        >
          {data?.imageUrl ? (
            <ImageContent>
              <ImageComponent
                source={{ uri: data.imageUrl }}
                height={imageHeight}
                width={containerWidth}
                resizeMode="contain"
                onLoad={(e) => {
                  const { width: imgWidth, height: imgHeight } =
                    e.nativeEvent.source;
                  setImageHeight((containerWidth * imgHeight) / imgWidth);
                }}
              />
            </ImageContent>
          ) : null}

          <TextContent>
            {data.title ? <Title>{data.title}</Title> : null}
            {data.description ? (
              <Description>{data.description}</Description>
            ) : null}
          </TextContent>

          {data?.buttonText ? (
            <ButtonContent>
              <Button text={data.buttonText} onPress={onPress} />
            </ButtonContent>
          ) : null}
        </Content>
      </Container>
    </Modal>
  );
});
