import React from "react";

import { useAlerts } from "~/state/alerts";
import { formatMessage } from "~/utils/translation";

import {
  Alert,
  AlertBox,
  AlertContainer,
  AlertText,
  AlertMessage,
  AlertButton,
  ButtonText,
} from "./styles";
import { messages } from "./intl";

export const Alerts = React.memo(() => {
  const { alerts, removeAlert } = useAlerts();

  return (
    <>
      {alerts.map(
        ({
          id,
          message,
          color,
          isSilent,
          isDismissable,
          onPress,
          onDismiss,
        }) => {
          const remove = () => {
            removeAlert(id);
          };

          const text =
            typeof message === "string"
              ? message
              : formatMessage(message as MessageDescriptorValues);

          const handleDismiss = () => {
            remove();

            if (typeof onDismiss === "function") {
              onDismiss();
            }
          };

          const handlePress = () => {
            remove();

            if (typeof onDismiss === "function") {
              onDismiss();
            }
            if (typeof onPress === "function") {
              onPress();
            }
          };

          if (isSilent) return null;

          if (isDismissable) {
            return (
              <AlertBox key={id}>
                <AlertContainer color={color}>
                  <AlertMessage onPress={handlePress}>
                    <AlertText>{message}</AlertText>
                  </AlertMessage>

                  <AlertButton onPress={handleDismiss}>
                    <ButtonText>{messages.dismiss}</ButtonText>
                  </AlertButton>
                </AlertContainer>
              </AlertBox>
            );
          }

          return (
            <Alert visible onDismiss={remove} key={id} color={color}>
              {text}
            </Alert>
          );
        }
      )}
    </>
  );
});
