import * as Localization from "expo-localization";
import { GestureResponderEvent } from "react-native";

import { useActionSheet } from "~/components/action-sheet";
import i18next, { formatMessage, getCurrentLocale } from "~/utils/translation";
import { getLibraryFiltersOptions } from "~/state/ui";
import { SUPPORTED_LOCALES } from "~/state/flamelink";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getLanguagesToDisplay, setLanguagesToDisplay } from "~/state/settings";
import { editProfile } from "~/state/user/actions";
import { asyncLogEvent, events } from "~/utils/analytics";
import { messages } from "./intl";

const defaultOptions = [
  formatMessage(messages.english),
  formatMessage(messages.spanish),
];
const defaultAppLocalesOptions = SUPPORTED_LOCALES;

const getResourcesOptionText =
  (
    languages: string[],
    currentLanguage?: { title: string; value: string[] }[]
  ) =>
  (text: string) => {
    const currentTitle = currentLanguage?.find(
      (item) => item.value.toString() === languages.toString()
    )?.title;
    if (currentTitle === text) {
      return `${text} ✓`;
    }
    return text;
  };

const getAppLanguageOptionText = (text: string, index: number) => {
  const currentLocale = getCurrentLocale();
  const availableLanguages = ["en", "es"];

  if (availableLanguages[index] === currentLocale) {
    return `${text} ✓`;
  }
  return text;
};

export const useChangeLanguages = () => {
  const { showActionSheetWithOptions } = useActionSheet();
  const dispatch = useAppDispatch();
  const libraryFiltersOptions = useAppSelector(getLibraryFiltersOptions);
  const languagesToDisplay = useAppSelector(getLanguagesToDisplay);
  const currentLocale = getCurrentLocale();

  const availableLanguages =
    libraryFiltersOptions?.languages[currentLocale]?.map(
      (language) => language.title
    ) || [];

  const currentSelection = libraryFiltersOptions?.languages[currentLocale];
  const languageOptions = [
    ...availableLanguages,
    formatMessage(messages.cancel),
  ].map(getResourcesOptionText(languagesToDisplay, currentSelection));

  const changeResourcesLanguages = (
    event?: GestureResponderEvent,
    position?: DOMRect
  ) => {
    showActionSheetWithOptions(
      {
        options: languageOptions,
        cancelButtonIndex: 3,
      },
      (selectedIndex) => {
        if (selectedIndex !== 3) {
          const currentLanguage =
            libraryFiltersOptions?.languages[currentLocale];
          if (!currentLanguage) {
            return;
          }
          const { value } = Object.values(currentLanguage)[selectedIndex!];

          dispatch(setLanguagesToDisplay(value));
          asyncLogEvent(events.RESOURCE_LANGUAGE_CHANGE, { value });
        }
      },
      // @ts-ignore
      event,
      position
    );
  };

  const changeAppLanguage = (
    cb: (key: string) => void,
    event: GestureResponderEvent,
    position: DOMRect
  ) => {
    const deviceLocale = Localization.locale.split("-")[0];
    const isDeviceLocaleSupported = SUPPORTED_LOCALES.includes(deviceLocale);

    const appLanguageOptions = isDeviceLocaleSupported
      ? [...defaultOptions, formatMessage(messages.system)]
      : defaultOptions;
    const options = [...appLanguageOptions, formatMessage(messages.cancel)].map(
      getAppLanguageOptionText
    );

    const cancelButtonIndex = isDeviceLocaleSupported ? 3 : 2;

    const appLocalesOptions = isDeviceLocaleSupported
      ? [...defaultAppLocalesOptions, deviceLocale]
      : defaultAppLocalesOptions;

    showActionSheetWithOptions(
      { options, cancelButtonIndex },
      (selectedIndex?: number) => {
        if (selectedIndex === undefined || selectedIndex === cancelButtonIndex)
          return;

        const newLanguage = appLocalesOptions[selectedIndex];
        if (newLanguage) {
          i18next.changeLanguage(newLanguage);
          dispatch(
            editProfile({
              appLanguage: newLanguage,
            })
          );
          asyncLogEvent(events.APP_LANGUAGE_CHANGE, { newLanguage });

          cb(newLanguage);
        }
      },
      // @ts-ignore
      event,
      position
    );
  };

  return { changeAppLanguage, changeResourcesLanguages };
};
