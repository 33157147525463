import * as React from "react";
import type { ListenToGodSection } from "~/state/flamelink/types";
import { RichText } from "../rich-text";
import { Question } from "../question";
import { SectionTitle, SubsectionTitle } from "../common";
import { Media } from "../media";
import { PrayerRequests } from "../prayer-requests";
import { Footnotes } from "../footnotes";
import { WhatWeBelieve } from "../what-we-believe";
import { ContentIcon } from "../content-icon";

interface ListenToGodProps {
  listenToGod: ListenToGodSection;
}

export const ListenToGod: React.FC<ListenToGodProps> = ({ listenToGod }) => {
  return (
    <>
      <SectionTitle>{listenToGod?.sectionTitle}</SectionTitle>

      <RichText>{listenToGod?.intro}</RichText>

      {Array.isArray(listenToGod?.questions) &&
        listenToGod?.questions.map(
          ({ question, uniqueKey, content }, index) => (
            <React.Fragment key={`content-repeater-${index}`}>
              <Question question={{ question, uniqueKey }} />

              <RichText>{content}</RichText>
            </React.Fragment>
          )
        )}

      <PersonalReflection listenToGod={listenToGod} />

      {listenToGod?.activate.content ||
      Array.isArray(listenToGod?.activate?.questions) ? (
        <>
          {listenToGod.activate.sectionTitle && (
            <SubsectionTitle>
              {listenToGod.activate.sectionTitle}
            </SubsectionTitle>
          )}

          {Array.isArray(listenToGod?.activate?.activateMedia) &&
            listenToGod?.activate?.activateMedia.map((fileId, index) => (
              <Media key={`activateMedia-${index}`} fileId={fileId} />
            ))}

          <RichText>{listenToGod?.activate.content}</RichText>

          {Array.isArray(listenToGod?.activate?.questions) &&
            listenToGod?.activate?.questions.map(
              ({ question, uniqueKey, content }, index) => (
                <React.Fragment key={`content-repeater-${index}`}>
                  <Question question={{ question, uniqueKey }} />

                  <RichText>{content}</RichText>
                </React.Fragment>
              )
            )}
        </>
      ) : null}

      {listenToGod?.prayerRequests && (
        <PrayerRequests prayerRequests={listenToGod.prayerRequests} />
      )}

      <WhatWeBelieve data={listenToGod?.whatWeBelieve} />

      <RichText>{listenToGod?.conclusion}</RichText>

      <Footnotes data={listenToGod?.footnotes} />

      <ContentIcon
        content={[
          listenToGod?.intro,
          listenToGod?.questions,
          listenToGod?.activate.content,
          listenToGod?.activate?.questions,
          listenToGod?.conclusion,
        ]}
      />
    </>
  );
};

const PersonalReflection: React.FC<ListenToGodProps> = ({ listenToGod }) => {
  const hasContent = React.useMemo(
    () =>
      listenToGod?.personalReflectionQuestions?.sectionTitle ||
      listenToGod?.personalReflectionQuestions?.disclaimer ||
      listenToGod?.personalReflectionQuestions?.intro ||
      listenToGod?.personalReflectionQuestions?.questionRepeater?.length ||
      listenToGod?.personalReflectionQuestions?.conclusion,
    [listenToGod]
  );
  return hasContent ? (
    <>
      <SubsectionTitle>
        {listenToGod.personalReflectionQuestions.sectionTitle}
      </SubsectionTitle>

      <RichText>{listenToGod?.personalReflectionQuestions.disclaimer}</RichText>

      <RichText>{listenToGod?.personalReflectionQuestions.intro}</RichText>

      {Array.isArray(
        listenToGod?.personalReflectionQuestions.questionRepeater
      ) &&
        listenToGod?.personalReflectionQuestions.questionRepeater.map(
          ({ question, uniqueKey, content }, index) => (
            <React.Fragment key={`content-repeater-${index}`}>
              <Question question={{ question, uniqueKey }} />

              <RichText>{content}</RichText>
            </React.Fragment>
          )
        )}

      <RichText>{listenToGod?.personalReflectionQuestions.conclusion}</RichText>
    </>
  ) : null;
};
