import React from "react";
import { View, Animated, Easing, StyleSheet } from "react-native";

import { Wave } from "./wave";

export const Clouds = () => {
  const translateY1 = React.useRef(new Animated.Value(0)).current;
  const translateY2 = React.useRef(new Animated.Value(0)).current;
  const translateY3 = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    const animate = (
      animatedValue: Animated.Value,
      duration: number,
      delay: number
    ) => {
      Animated.loop(
        Animated.sequence([
          Animated.timing(animatedValue, {
            toValue: -10,
            duration,
            easing: Easing.sin,
            useNativeDriver: false,
          }),
          Animated.timing(animatedValue, {
            toValue: 0,
            duration,
            easing: Easing.sin,
            useNativeDriver: false,
          }),
        ]),
        {
          iterations: -1, // Infinite loop
          delay,
        }
      ).start();
    };

    animate(translateY1, 3500, 0);
    animate(translateY2, 5000, 500);
    animate(translateY3, 2000, 1000);
  }, [translateY1, translateY2, translateY3]);

  return (
    <View style={StyleSheet.absoluteFill}>
      <Animated.View
        style={[
          StyleSheet.absoluteFill,
          {
            transform: [{ translateY: translateY1 }],
          },
        ]}
      >
        <Wave index={0} />
      </Animated.View>
      <Animated.View
        style={[
          StyleSheet.absoluteFill,
          {
            transform: [{ translateY: translateY2 }],
          },
        ]}
      >
        <Wave index={1} />
      </Animated.View>
      <Animated.View
        style={[
          StyleSheet.absoluteFill,
          {
            transform: [{ translateY: translateY3 }],
          },
        ]}
      >
        <Wave index={2} />
      </Animated.View>
    </View>
  );
};
