import { createAction } from "@reduxjs/toolkit";
import type {
  DeleteDownloadPayload,
  DownloadFilePayload,
  DownloadSessionPayload,
  DownloadVolumePayload,
  OpenFilePayload,
  LoadFlamelinkFilePayload,
  LoadFlamelinkFilesPayload,
  LoadFilesPayload,
  LoadVolumesPayload,
  LoadPlansPayload,
  LoadSessionAction,
  LoadPersonalDevotionAction,
  LoadLessonAction,
  LoadLessonsBySessionIdAction,
  ProcessZipFileAction,
} from "./types";

export const downloadFile = createAction<DownloadFilePayload>("downloadFile");

export const deleteDownload =
  createAction<DeleteDownloadPayload>("deleteDownload");

export const deleteAllDownloads = createAction("deleteAllDownloads");

export const openFile = createAction<OpenFilePayload>("openFile");

export const downloadSession =
  createAction<DownloadSessionPayload>("downloadSession");

export const downloadVolume =
  createAction<DownloadVolumePayload>("downloadVolume");

export const loadFlamelinkFile =
  createAction<LoadFlamelinkFilePayload>("loadFlamelinkFile");

export const loadFlamelinkFiles =
  createAction<LoadFlamelinkFilesPayload>("loadFlamelinkFiles");

export const loadAllFlamelinkFiles = createAction<LoadFilesPayload>(
  "loadAllFlamelinkFiles"
);

export const loadVolumeFiles =
  createAction<LoadVolumesPayload>("loadVolumeFiles");

export const loadPlanFiles = createAction<LoadPlansPayload>("loadPlanFiles");

export const loadSession =
  createAction<LoadSessionAction["payload"]>("loadSession");

export const loadPersonalDevotion = createAction<
  LoadPersonalDevotionAction["payload"]
>("loadPersonalDevotion");

export const loadLesson =
  createAction<LoadLessonAction["payload"]>("loadLesson");

export const processZipFile =
  createAction<ProcessZipFileAction["payload"]>("processZipFile");

export const loadLessonsBySessionId = createAction<
  LoadLessonsBySessionIdAction["payload"]
>("loadLessonsBySessionId");
