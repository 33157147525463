import * as React from "react";

import { InputBox } from "~/components/auth-screen/styles";
import { Button } from "~/components/button";
import { ButtonTypes } from "~/components/button/types";
import { LocationInput } from "~/components/location-input";
import { getCurrentLocale } from "~/utils/translation";
import ProfanityFilter from "~/utils/profanityFilter";
import { isWeb } from "~/utils/platform";

import { messages as genericMessages } from "../intl";
import { ChurchNameData } from "../types";
import { Container, Content, ButtonBox, BottomWrapper } from "../styles";
import { Meta, Title } from "./styles";
import { messages } from "./intl";
import type { GooglePlaceData } from "react-native-google-places-autocomplete";
import Pager from "~/components/pager";

import { useChurchNameData } from "./utils";
import { useGooglePlaces } from "~/utils/location";

type Props = {
  onPress: (data: ChurchNameData) => void;
  onBackPress: () => void;
  churchName?: string;
  churchCity?: string;
  churchState?: string;
  progressStep: (step: number) => void;
};

const useChurchData = (
  churchName: string,
  churchCity: string = "",
  churchState: string = "",
  size: number = 10
) => {
  const { data: savedData } = useChurchNameData(
    churchName,
    churchCity,
    churchState
  );
  const { places } = useGooglePlaces(churchName, "church");

  const [data, setData] = React.useState(new Map());

  React.useEffect(() => {
    const profanityFilter = new ProfanityFilter({
      language: getCurrentLocale(),
    });

    if (!churchName) {
      setData(new Map());
      return;
    }

    const filteredData = savedData.filter((item) =>
      item.toLowerCase().startsWith(churchName.toLowerCase())
    );

    const updatedData = [...filteredData, ...places];

    const result = updatedData.reduce((acc, item) => {
      if (acc.size >= size) {
        return acc;
      }
      const value = profanityFilter.replace(item);
      acc.set(value, value);
      return acc;
    }, new Map());

    setData(result);
  }, [churchName, savedData, places, size]);

  return {
    data,
  };
};

export const ChurchNameInputs = React.memo<Props>(
  ({
    churchName: defaultChurchName = "",
    onPress,
    progressStep,
    churchCity,
    churchState,
  }) => {
    const [churchName, setChurchName] = React.useState(defaultChurchName);

    const { data: churchData } = useChurchData(
      churchName,
      churchCity,
      churchState
    );

    const handleSubmit = React.useCallback(() => {
      if (churchName) {
        onPress({ churchName });
      }
    }, [onPress, churchName]);

    const handlePress = (data: GooglePlaceData) => {
      const [name = ""] = data?.description?.split(/\s*\-|,\s*/g);
      setChurchName(name);
    };

    const handleTextChange = (data: string) => {
      setChurchName(data);
    };

    const keyProps = isWeb ? { key: churchName } : {};

    return (
      <>
        <Container>
          <Content scrollEnabled={false} keyboardShouldPersistTaps="always">
            <Title>{messages.churchTitle}</Title>
            <Meta>{messages.churchNameMeta}</Meta>
            <InputBox>
              <LocationInput
                type="church"
                label={messages.churchName}
                onPress={handleTextChange}
                onChangeText={handleTextChange}
                value={churchName}
                options={churchData}
                ignoreFilter
              />
            </InputBox>
          </Content>
        </Container>

        <BottomWrapper>
          <Pager
            pages={3}
            currentPage={1}
            isValid={!!churchName}
            setPage={progressStep}
          />

          <ButtonBox>
            <Button
              text={genericMessages.buttonNext}
              onPress={handleSubmit}
              type={ButtonTypes.Primary}
              isDisabled={!churchName}
              isUppercase
            />
          </ButtonBox>
        </BottomWrapper>
      </>
    );
  }
);
