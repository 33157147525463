import * as React from "react";

import { AuthScreen } from "~/components/auth-screen";
import { Button } from "~/components/button";
import { Loader } from "~/components/loader";
import { ButtonTypes } from "~/components/button/types";
import {
  sendEmailVerification,
  preLogout,
  startEmailVerificationPolling,
  stopEmailVerificationPolling,
} from "~/state/user/actions";
import { useAppSelector, useAppDispatch } from "~/state/hooks";
import { getUserEmail } from "~/state/user/selectors";
import { colors } from "~/styles/theme";
import { genericMessages as errorMessages } from "~/constants/intl";
import { useAlerts } from "~/state/alerts";

import { messages } from "./intl";
import { LoaderContainer } from "./styles";

import { messages as genericMessages } from "../intl";
import {
  Subtitle,
  Disclaimer,
  ButtonWrapper,
  DoubleButtonBox,
} from "../styles";

export const EmailVerification = () => {
  const [isSending, setIsSending] = React.useState(false);

  const email = useAppSelector(getUserEmail);
  const dispatch = useAppDispatch();
  const { pushAlert, alerts } = useAlerts();

  const onSuccess = React.useCallback(() => {
    pushAlert({
      message: messages.emailVerificationSent,
      color: colors.green600,
    });
    setIsSending(false);
  }, [pushAlert]);

  const onError = React.useCallback(
    (error: string = "") => {
      const message = error.includes("auth/too-many-requests")
        ? errorMessages.errorTooManyRequests
        : errorMessages.error;
      pushAlert({ message, color: colors.black });
      setIsSending(false);
    },
    [pushAlert]
  );

  const handleResend = React.useCallback(() => {
    setIsSending(true);
    dispatch(sendEmailVerification({ onSuccess, onError }));
  }, [dispatch, onError, onSuccess]);

  React.useEffect(() => {
    dispatch(startEmailVerificationPolling());

    return () => {
      dispatch(stopEmailVerificationPolling());
    };
  }, [dispatch]);

  const handleBack = React.useCallback(() => dispatch(preLogout()), [dispatch]);

  return (
    <AuthScreen
      screenName="email-verification"
      title={messages.emailVerificationTitle}
      hideSocialButtons
      id="email-verification"
    >
      <Subtitle>
        {{ ...messages.emailVerificationSubtitle, values: { email } }}
      </Subtitle>

      <LoaderContainer>
        <Loader />
      </LoaderContainer>

      <Disclaimer>{messages.emailVerificationDisclaimer}</Disclaimer>

      <ButtonWrapper>
        <DoubleButtonBox>
          <Button
            text={genericMessages.buttonPrevious}
            onPress={handleBack}
            type={ButtonTypes.Outlined}
          />
        </DoubleButtonBox>
        <DoubleButtonBox isLast>
          <Button
            text={messages.emailVerificationResend}
            onPress={handleResend}
            type={ButtonTypes.Primary}
            isLoading={isSending}
          />
        </DoubleButtonBox>
      </ButtonWrapper>
    </AuthScreen>
  );
};
